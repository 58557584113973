import client from '@config/client'
import API_URL from '@config/services'

export const onCreateDiscount = payload => {
  return client
    .post(API_URL.DISCOUNTS.POST_DISCOUNT(), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onDeleteDiscount = discountId => {
  return client
    .delete(API_URL.DISCOUNTS.DELETE_DISCOUNT(discountId))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onUpdateDiscount = (discountId, payload) => {
  return client
    .patch(API_URL.DISCOUNTS.PATCH_DISCOUNT(discountId), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}
