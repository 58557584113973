import React, { useMemo, useState } from 'react'

import { useTranslation } from '@contexts/translation'
import { Controller } from 'react-hook-form'

import {
  Grid,
  ListSubheader,
  MenuItem,
  TextField,
  Typography
} from '@mui/material'
import { Add } from '@mui/icons-material'
import CommonModal from '@components/common/Modal/CommonModal'
import TenantForm from '@components/Invoicing/Tenants/TenantForm'
import { Stack } from '@mui/system'
import { format } from 'date-fns'
import DatePicker from '@components/form/DatePicker'
import DatesStepPeriods from '../dates/DatesStepPeriods'
import { onCreateLease, onUpdateLease } from '@api/lease'
import { responseHandler } from '@utils/responseHandler'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

export default function GeneralInfoStep(props) {
  const {
    operationId,
    leaseId = null,
    control,
    setValue,
    setError,
    errors,
    fullWidth,
    fetchTenantsURL,
    fetchPeriodsURL,
    tenants,
    setSelectedLeaseId,
    selectedLots,
    setSelectedLots,
    lotsData,
    isLotsLoading,
    periods,
    isPeriodsLoading,
    handleEndDate,
    watchTenant
  } = props
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [openCreateTenant, setOpenCreateTenant] = useState()

  // Lots Section
  const handleSelectLot = event => {
    const {
      target: { value }
    } = event
    setSelectedLots(typeof value === 'string' ? value.split(',') : value)
  }

  const othersLots = useMemo(
    () => lotsData?.results?.filter(l => !l.is_parking),
    [lotsData]
  )

  const parkingLots = useMemo(
    () => lotsData?.results?.filter(l => l.is_parking),
    [lotsData]
  )

  // Tenants Section

  const handleSubmitLease = async value => {
    const payload = {
      operation: Number(operationId),
      tenant: Number(value),
      start_date: format(new Date(), 'dd-MM-yyyy'),
      end_date: format(new Date(), 'dd-MM-yyyy')
    }
    const response = await onCreateLease(payload)

    responseHandler({
      response,
      callback: () => {
        setSelectedLeaseId(response.data.id)
      },
      callbackError: () => {
        const { errors } = response.data
        if (errors?.length > 0) {
          errors.map(error => {
            setError(error.field, {
              message: error.message
            })
          })
        }
      },
      dictionary,
      msgSuccess: dictionary.lease_created,
      snackbar: enqueueSnackbar
    })
  }

  const handleEditLease = async value => {
    const payload = {
      operation: Number(operationId),
      tenant: Number(value)
    }

    const response = await onUpdateLease(leaseId, payload)

    responseHandler({
      response,
      callback: () => {
        setSelectedLeaseId(response.data.id)
      },
      callbackError: () => {
        const { errors } = response.data
        if (errors?.length > 0) {
          errors.map(error => {
            setError(error.field, {
              message: error.message
            })
          })
        }
      },
      dictionary,
      msgSuccess: dictionary.lease_updated,
      snackbar: enqueueSnackbar
    })
  }

  const handleSelectTenant = id => {
    setValue('tenant', id)
    leaseId ? handleEditLease(id) : handleSubmitLease(id)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="dark.main" paddingX={4}>
            {dictionary.general_information}
          </Typography>
          <Typography fontSize={13} mt={1} paddingX={4}>
            {dictionary.general_information_desc}
          </Typography>
          <Grid
            item
            xs={12}
            sx={{
              mt: 3,
              mb: 0,
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
          >
            <Grid xs={6} pr={1.5} pl={4}>
              <Stack gap={1}>
                <Typography variant="caption">{dictionary.tenant}</Typography>
                <Controller
                  control={control}
                  name="tenant"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      fullWidth={fullWidth ?? true}
                      variant="filled"
                      error={errors.tenant}
                      helperText={errors.tenant && errors.tenant.message}
                      InputProps={{
                        style: {
                          height: '44px'
                        }
                      }}
                      sx={{
                        '& .MuiInputBase-input': {
                          paddingY: 0,
                          marginY: 0,
                          height: '100%'
                        }
                      }}
                      value={watchTenant}
                    >
                      <MenuItem
                        key={operationId}
                        value={null}
                        onClick={() => setOpenCreateTenant(true)}
                      >
                        <Add sx={{ mr: 1 }} /> {dictionary.create_tenant}
                      </MenuItem>
                      {tenants &&
                        tenants.results.map(tenant => (
                          <MenuItem
                            key={tenant.id}
                            value={tenant.id}
                            onClick={() => handleSelectTenant(tenant.id)}
                          >
                            {tenant.display}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Stack>
            </Grid>
            <Grid xs={6} pr={1.5} pl={4}>
              <Stack gap={1}>
                <Typography variant="caption">
                  {dictionary.Email_for_leases}
                </Typography>
                <Controller
                  control={control}
                  name="invoice_email_to"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      label={dictionary.to}
                      type="email"
                      error={Boolean(errors.invoice_email_to)}
                      errorMessage={
                        errors.invoice_email_to &&
                        errors.invoice_email_to.message
                      }
                      InputProps={{ style: { height: '44px' } }}
                    />
                  )}
                />
              </Stack>
            </Grid>

            <DatesStepPeriods
              operationId={operationId}
              leaseId={leaseId}
              setLeaseValue={setValue}
              periods={periods}
              fetchPeriodsURL={fetchPeriodsURL}
              isLoading={isPeriodsLoading}
              control={control}
              handleEndDate={handleEndDate}
            />

            <Grid xs={6} pr={1.5} pl={4}>
              <Stack gap={1}>
                <Typography variant="caption">
                  {dictionary.date_signed}
                </Typography>
                <DatePicker
                  name="date_signed"
                  variant="outlined"
                  fullWidth
                  control={control}
                  data_testid="DatePicker-b8c90028-ae63-4b77-a20e-8f7f70a2d85e"
                  InputProps={{
                    style: { height: '44px' }
                  }}
                  sx={{
                    '& .MuiFilledInput-input': {
                      paddingTop: 1.5,
                      paddingBottom: 1.5
                    }
                  }}
                />
              </Stack>
            </Grid>
            <Grid xs={6} pr={1.5} pl={4}>
              <Stack gap={1}>
                <Typography variant="caption">{dictionary.lot}</Typography>
                <TextField
                  select
                  fullWidth
                  isLoading={isLotsLoading}
                  placeholder={dictionary.lease_article}
                  SelectProps={{
                    multiple: true,
                    value: selectedLots,
                    onChange: handleSelectLot
                  }}
                  InputProps={{
                    style: {
                      height: '44px'
                    }
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      paddingY: 0,
                      marginY: 0,
                      height: '100%'
                    }
                  }}
                >
                  {othersLots?.length > 0 && (
                    <ListSubheader>{dictionary.lots}</ListSubheader>
                  )}

                  {othersLots?.map(lot => (
                    <MenuItem
                      key={lot.id}
                      value={lot.id}
                      disabled={
                        !lot.is_vacant && !selectedLots.includes(lot.id)
                      }
                      data-testid="MenuItem-ec0228ea-9e4f-4581-8772-b09d03b45adc"
                    >
                      {lot.building_display ? `${lot.building_display} : ` : ''}
                      {lot.floor_display ? `${lot.floor_display} : ` : ''}
                      {lot.name + ' '}
                      {lot?.type.includes('parking')
                        ? ``
                        : ` : ${lot.rentable_surface.toFixed()}m2 `}
                    </MenuItem>
                  ))}

                  {parkingLots?.length > 0 && (
                    <ListSubheader>{dictionary.parking}</ListSubheader>
                  )}

                  {parkingLots?.map(lot => (
                    <MenuItem
                      key={lot.id}
                      value={lot.id}
                      disabled={
                        !lot.is_vacant && !selectedLots.includes(lot.id)
                      }
                      data-testid="MenuItem-61c16096-4007-43e1-ad42-08ee3a24fe75"
                    >
                      {lot.building_display ? `${lot.building_display} : ` : ''}
                      {lot.floor_display ? `${lot.floor_display} : ` : ''}
                      {lot.name + ' '}
                    </MenuItem>
                  ))}

                  {!lotsData && (
                    <MenuItem
                      value={0}
                      disabled
                      data-testid="MenuItem-8754521f-da80-4f80-a7c8-7b8f9734133a"
                    >
                      {dictionary.loading}
                    </MenuItem>
                  )}
                </TextField>
              </Stack>
            </Grid>
            <Grid xs={6} pr={1.5} pl={4}>
              <Stack gap={1}>
                <Typography variant="caption">{dictionary.deposit}</Typography>
                <Controller
                  control={control}
                  name="make_deposit"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      variant="filled"
                      control={control}
                      error={Boolean(errors.make_deposit)}
                      errorMessage={
                        errors.make_deposit && errors.make_deposit.message
                      }
                      InputProps={{
                        style: {
                          height: '44px'
                        }
                      }}
                      sx={{
                        '& .MuiInputBase-input': {
                          paddingY: 0,
                          marginY: 0,
                          height: '100%'
                        }
                      }}
                    >
                      <MenuItem
                        value={true}
                        data-testid="MenuItem-cc2b38e3-c1bd-43fe-b771-dcb9713e7da8"
                      >
                        {dictionary.yes}
                      </MenuItem>
                      <MenuItem
                        value={false}
                        data-testid="MenuItem-f040d563-b1c0-4bc8-9d1c-8df44628d07d"
                      >
                        {dictionary.no}
                      </MenuItem>
                    </TextField>
                  )}
                />
              </Stack>
            </Grid>
            <Grid xs={6} pr={1.5} pl={4}>
              <Stack gap={1}>
                <Typography variant="caption">
                  {dictionary.indexation}
                </Typography>
                <Controller
                  control={control}
                  name="is_indexed"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      variant="filled"
                      control={control}
                      error={Boolean(errors.is_indexed)}
                      errorMessage={
                        errors.is_indexed && errors.is_indexed.message
                      }
                      InputProps={{
                        style: {
                          height: '44px'
                        }
                      }}
                      sx={{
                        '& .MuiInputBase-input': {
                          paddingY: 0,
                          marginY: 0,
                          height: '100%'
                        }
                      }}
                    >
                      <MenuItem value={true}>{dictionary.yes}</MenuItem>
                      <MenuItem value={false}>{dictionary.no}</MenuItem>
                    </TextField>
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CommonModal
        open={openCreateTenant}
        title={`${dictionary.add} ${dictionary.tenant}`}
        onClose={() => setOpenCreateTenant(false)}
        maxWidth={'sm'}
      >
        <TenantForm
          operationId={operationId}
          tenantsURL={fetchTenantsURL}
          setTenantValue={id => {
            setValue('tenant', id)
            leaseId ? handleEditLease(id) : handleSubmitLease(id)
          }}
          handleClose={() => setOpenCreateTenant(false)}
        />
      </CommonModal>
    </>
  )
}

GeneralInfoStep.propTypes = {
  operationId: PropTypes.number,
  leaseId: PropTypes.number,
  control: PropTypes.object,
  setValue: PropTypes.func,
  setError: PropTypes.func,
  errors: PropTypes.object,
  defaultValue: PropTypes.object,
  fullWidth: PropTypes.bool,
  fetchTenantsURL: PropTypes.string,
  fetchPeriodsURL: PropTypes.string,
  tenants: PropTypes.object,
  setSelectedLeaseId: PropTypes.func,
  selectedLots: PropTypes.array,
  setSelectedLots: PropTypes.func,
  lotsData: PropTypes.object,
  isLotsLoading: PropTypes.bool,
  periods: PropTypes.object,
  isPeriodsLoading: PropTypes.bool,
  handleEndDate: PropTypes.func,
  watchTenant: PropTypes.number
}
