import { Controller } from 'react-hook-form'
import {
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemText,
  Checkbox,
  Typography
} from '@mui/material'
import { useTranslation } from '@contexts/translation'
import { SelectSkeleton } from '@components/common/Skeletons'
import PropTypes from 'prop-types'

const SelectItem = ({
  name,
  label,
  control,
  defaultValue,
  isLoading = false,
  multiple = false,
  errorMessage,
  children,
  elements = { items: [], id: '', display: '' }, // { items: array, id: 'id field' (can be 'id', 'pk'...), display: 'display field' (can be 'name', 'display', 'title'...) }
  showCount = false,
  setSelectedOpId,
  helpText = '',
  data_testid,
  ...props
}) => {
  const { t } = useTranslation()
  const labelId = `${name}-label`

  return (
    <>
      {!isLoading && (
        <FormControl {...props} variant="filled" margin="normal">
          <InputLabel id={labelId}>{label}</InputLabel>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => {
              return (
                <Select
                  labelId={labelId}
                  label={label}
                  data-testid={data_testid}
                  multiple={multiple}
                  value={
                    isLoading && multiple ? [] : isLoading ? '' : field.value
                  }
                  renderValue={
                    multiple && showCount
                      ? selected =>
                          t('select_number_selected', {
                            count: selected.length
                          })
                      : multiple && !children
                      ? selected => {
                          const retElems = elements.items
                            .filter(el => selected.includes(el[elements.id]))
                            .map(el => el[elements.display])
                          if (retElems.every(el => typeof el === 'string'))
                            return retElems.join(', ')
                          return retElems
                        }
                      : undefined
                  }
                  {...field}
                  onChange={e => {
                    const value = e.target.value
                    if (e.target?.name === 'project' && setSelectedOpId) {
                      setSelectedOpId(value)
                    }
                    if (multiple && !children) {
                      if (value[value.length - 1] === 'all') {
                        field.onChange(
                          field.value.length === elements.items.length
                            ? []
                            : elements.items.map(el => el[elements.id])
                        )

                        return
                      } else if (
                        value[value.length - 1]?.toString().includes('group')
                      ) {
                        const groupValue = value[value.length - 1].split('_')[1]
                        const itemsGroup = elements.items
                          .filter(el => el.group == groupValue && !el.is_group)
                          .map(el => el[elements.id])
                        field.onChange(
                          field.value.filter(v => itemsGroup.includes(v))
                            .length === itemsGroup.length
                            ? field.value.filter(v => !itemsGroup.includes(v))
                            : [...field.value, ...itemsGroup]
                        )

                        return
                      }
                    }
                    field.onChange(value)
                  }}
                  data_testid="Select-42dbbbc3-8829-48bf-af05-9c71fdc84736"
                  sx={{
                    height: '45px'
                  }}
                >
                  {children &&
                    (isLoading ? (
                      <MenuItem
                        disabled
                        value=""
                        data-testid="MenuItem-88fc8a78-d9e9-4ce9-b63b-e74c145fb5a8"
                      >
                        {t('select_loading')}
                      </MenuItem>
                    ) : !children?.length ? (
                      <MenuItem
                        disabled
                        value=""
                        data-testid="MenuItem-f8bdbc90-7889-4564-a373-ba2659f6d8c4"
                      >
                        {t('select_no_data')}
                      </MenuItem>
                    ) : (
                      children
                    ))}

                  {multiple && !children && (
                    <MenuItem
                      value="all"
                      data-testid="MenuItem-4df45535-cfc8-416e-8e22-12d8cb35b33b"
                    >
                      <Checkbox
                        sx={{ mr: 1 }}
                        checked={field.value.length === elements.items.length}
                        indeterminate={
                          field.value.length < elements.items.length &&
                          field.value.length > 0
                        }
                        data-testid="Checkbox-1f2bd98a-424b-437d-977c-7ffdc9d3547b"
                      />

                      <b>{t('select_select_all')}</b>
                    </MenuItem>
                  )}

                  {multiple &&
                    !children &&
                    elements.items.map(el => {
                      const itemsGroup = elements.items
                        .filter(it => it.group == el.group && !it.is_group)
                        .map(it => it[elements.id])
                      const valueFiltered = field.value.filter(v =>
                        itemsGroup.includes(v)
                      )

                      return el.is_group ? (
                        <MenuItem
                          key={el.group}
                          sx={{ display: 'flex', alignItems: 'center' }}
                          value={`group_${el.group}`}
                          data-testid="MenuItem-a206b275-201a-498a-9350-1689a01c073f"
                        >
                          <Checkbox
                            sx={{ mr: 1 }}
                            checked={itemsGroup.length === valueFiltered.length}
                            indeterminate={
                              itemsGroup.length !== valueFiltered.length &&
                              valueFiltered.length > 0
                            }
                            data-testid="Checkbox-a54713fd-9a2e-4dd1-90d1-a7958ae0b025"
                          />

                          <b>{el[elements.display]}</b>
                        </MenuItem>
                      ) : (
                        <MenuItem
                          key={el[elements.id]}
                          value={el[elements.id]}
                          data-testid="MenuItem-f3e95e15-d2ee-473e-91a6-5f927c0946ff"
                        >
                          <Checkbox
                            sx={{ mr: 1, ml: el.is_group === false ? 2 : 0 }}
                            checked={field.value.indexOf(el[elements.id]) > -1}
                            data-testid="Checkbox-cf21813b-72f5-407f-acdb-43f83f46b176"
                          />

                          <ListItemText primary={el[elements.display]} />
                        </MenuItem>
                      )
                    })}
                </Select>
              )
            }}
          />

          {errorMessage && (
            <FormHelperText error>{errorMessage}</FormHelperText>
          )}

          {helpText && (
            <Typography
              sx={{ marginTop: 1 }}
              data-testid="Typography-1286b129-059b-43e3-bc47-4b10dcb9aff9"
            >
              {helpText}
            </Typography>
          )}
        </FormControl>
      )}

      {isLoading && <SelectSkeleton />}
    </>
  )
}

export default SelectItem

SelectItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isLoading: PropTypes.bool,
  multiple: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.array,
  elements: PropTypes.object,
  showCount: PropTypes.bool,
  setSelectedOpId: PropTypes.func,
  helpText: PropTypes.string,
  data_testid: PropTypes.string
}
