import React, { useState } from 'react'

import { useTranslation } from '@contexts/translation'
import useButtonTabsStyle from '@styles/buttonTabs/buttonTabs'
import clsx from 'clsx'

import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'

import { Grid, IconButton, Stack, Tab, Tabs } from '@mui/material'
import {
  InfoOutlined,
  SummarizeOutlined,
  CheckCircleOutlined,
  StickyNote2Outlined,
  FolderCopyOutlined,
  Close
} from '@mui/icons-material'

import InfoTab from './tabs/InfoTab'
import Tickets from './tabs/Tickets'
import Files from '@components/Files/Files'
import queryString from '@utils/queryString'
import LeaseConditionsTab from './tabs/LeaseConditionsTab'
import InvoicingTab from './tabs/InvoicingTab'

export default function LeaseDetails(props) {
  const {
    operationId,
    leaseId,
    currency,
    fetchURL,
    handleClose,
    ACTIONS,
    cancelStatus
  } = props
  const { dictionary } = useTranslation()

  const classesButtonTabs = useButtonTabsStyle()

  const leaseURL = leaseId ? API_URL.LEASES.GET_LEASE(leaseId) : null
  const { data: leaseData } = useFetchData(leaseURL)

  const [value, setValue] = useState('info')

  const TAB_ITEMS = [
    {
      id: 0,
      icon: <InfoOutlined sx={{ fontSize: 15 }} />,
      label: dictionary.information,
      value: 'info',
      component: (
        <InfoTab
          operationId={operationId}
          leaseId={leaseId}
          leaseData={leaseData}
          currency={currency}
          fetchURL={fetchURL}
          handleClose={handleClose}
          cancelStatus={cancelStatus}
          setValue={setValue}
          ACTIONS={ACTIONS}
        />
      )
    },
    {
      id: 1,
      icon: <SummarizeOutlined sx={{ fontSize: 15 }} />,
      label: dictionary.lease_conditions,
      value: 'lease_conditions',
      component: <LeaseConditionsTab leaseId={leaseId} currency={currency} />
    },
    {
      id: 2,
      icon: <CheckCircleOutlined sx={{ fontSize: 15 }} />,
      label: dictionary.invoicing,
      value: 'invoicing',
      component: (
        <InvoicingTab
          operationId={operationId}
          leaseId={leaseId}
          currency={currency}
          leaseData={leaseData}
        />
      )
    },
    {
      id: 3,
      icon: <StickyNote2Outlined sx={{ fontSize: 15 }} />,
      label: dictionary.tickets,
      value: 'tickets',
      component: (
        <Tickets
          operationId={operationId}
          leaseId={leaseId}
          ticketsURL={API_URL.TICKETS.GET_TICKETS()}
        />
      )
    },
    {
      id: 4,
      icon: <FolderCopyOutlined sx={{ fontSize: 15 }} />,
      label: dictionary.files,
      value: 'files',
      component: (
        <Files
          leaseId={leaseId}
          filesURL={
            API_URL.FILES.GET_FILES() + '?' + queryString({ leases: leaseId })
          }
        />
      )
    }
  ]

  return (
    <>
      <Grid container sx={{ backgroundColor: 'other.extralight' }}>
        <Grid item xs={12}>
          <Stack direction="row" sx={{ p: 2 }}>
            <Tabs
              variant="standard"
              sx={{
                borderBottom: t => `1px solid ${t.palette.other.stroke}`,
                width: '100%'
              }}
            >
              {TAB_ITEMS.map(tab => (
                <Tab
                  key={tab.id}
                  className={clsx(classesButtonTabs.detailsRoot, {
                    [classesButtonTabs.detailsSelected]: value === tab.value
                  })}
                  onClick={() => setValue(tab.value)}
                  label={
                    <div style={{ display: 'flex', gap: 5, padding: 2 }}>
                      {tab.icon}
                      {tab.label}
                    </div>
                  }
                />
              ))}
            </Tabs>
            <Grid
              sx={{ borderBottom: t => `1px solid ${t.palette.other.stroke}` }}
            >
              <IconButton
                sx={{
                  color: t => t.palette.secondary.dark
                }}
                onClick={handleClose}
              >
                <Close sx={{ fontSize: 20 }} />
              </IconButton>
            </Grid>
          </Stack>
        </Grid>

        <Grid item xs={12} padding={3}>
          {TAB_ITEMS.map((tab, i) => (
            <Grid container key={i}>
              {value === tab.value && tab.component}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}
