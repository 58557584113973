import React, { useEffect, useState } from 'react'

import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'

import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import PlaceHolder from '@components/common/PlaceHolder'
import numberWithCommas from '@utils/numberWithCommas'
import queryString from '@utils/queryString'
import roundNumber from '@utils/roundNumber'

export default function LotsTable(props) {
  const { leaseId, type, currency } = props
  const { dictionary, t } = useTranslation()
  const classesTable = useTableStyles()

  const [articles, setArticles] = useState([])

  const fetchURL = type
    ? API_URL.LEASES.GET_LEASE_ARTICLES(leaseId) + '?' + queryString({ type })
    : API_URL.LEASES.GET_LEASE_ARTICLES(leaseId)
  const { data, isLoading } = useFetchData(leaseId ? fetchURL : null)

  useEffect(() => {
    if (data) setArticles(data.results)
  }, [data])

  const getTotalSurface = () => {
    const balanceArray = articles?.map(item =>
      Number(item.contract_surface_area)
    )

    return balanceArray?.reduce((prev, curr) => prev + curr, 0)
  }

  const getTotalRent = () => {
    const rentArray = articles?.map(item => Number(item.rent))

    return rentArray?.reduce((prev, curr) => prev + curr, 0)
  }

  const getTotalExpenses = () => {
    const rentArray = articles?.map(item => Number(item.budget_provision))

    return rentArray?.reduce((prev, curr) => prev + curr, 0)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableContainer className={classesTable.container}>
          <Table>
            {articles?.length > 0 && (
              <TableHead>
                <TableRow hover={false}>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.lot}
                  </TableCell>
                  <TableCell align="right" className={classesTable.headerCell}>
                    {dictionary.surface}
                  </TableCell>
                  <TableCell align="right" className={classesTable.headerCell}>
                    {dictionary['rent/m2']}
                  </TableCell>
                  <TableCell align="right" className={classesTable.headerCell}>
                    {dictionary.rent}
                  </TableCell>
                  <TableCell align="right" className={classesTable.headerCell}>
                    {dictionary['expenses/m2']}
                  </TableCell>
                  <TableCell align="right" className={classesTable.headerCell}>
                    {dictionary.expenses}
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading && (
                <TableRow className={classesTable.row}>
                  <TableCell align="center" colSpan={'100%'}>
                    <CircularProgress color="secondary" />
                  </TableCell>
                </TableRow>
              )}

              {!isLoading && data && !data.results.length && (
                <TableRow hover={false} className={classesTable.row}>
                  <TableCell align="center" colSpan={'100%'}>
                    <PlaceHolder
                      src="/Placeholders/Keysy/Lots.png"
                      wordingBordered={t('project_lots_lot_none')}
                    />
                  </TableCell>
                </TableRow>
              )}

              {articles.map(article => (
                <TableRow key={article.id} className={classesTable.row}>
                  <TableCell>{article.lot_display}</TableCell>
                  <TableCell align="right">
                    {numberWithCommas(article.contract_surface_area, 2)}
                  </TableCell>
                  <TableCell align="right">
                    {`${roundNumber(article.price_per_surface, 3)} ${currency}`}
                  </TableCell>
                  <TableCell align="right">
                    {numberWithCommas(article.rent, 2, currency)}
                  </TableCell>
                  <TableCell align="right">
                    {`${roundNumber(
                      article.contract_budget_per_surface,
                      3
                    )} ${currency}`}
                  </TableCell>
                  <TableCell align="right">
                    {numberWithCommas(article.budget_provision, 2, currency)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {articles.length > 0 && (
              <TableFooter className={classesTable.footer}>
                <TableRow hover={false} className={classesTable.rowTotal}>
                  <TableCell colSpan={1} align="left">
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      data-testid="Typography-927d8cfb-31b1-4de8-9c94-12a8f2a62ae2"
                    >
                      {dictionary.total}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={1} align="right">
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      data-testid="Typography-4bd6bbe3-5860-4fd6-adea-45ec04d6c347"
                    >
                      {numberWithCommas(getTotalSurface() || 0, 2)}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2} align="right">
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      data-testid="Typography-7d06687a-ad07-4d3c-8b51-dba0d552ffe2"
                    >
                      {numberWithCommas(getTotalRent() || 0, 2, currency)}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2} align="right">
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      data-testid="Typography-81c0e601-02b3-4fe0-97fc-8d67ec04825c"
                    >
                      {numberWithCommas(getTotalExpenses() || 0, 2, currency)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
