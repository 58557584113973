import React, { useEffect, useState } from 'react'

import { useTranslation } from '@contexts/translation'

import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import CommonModal from '@components/common/Modal/CommonModal'
import DiscountForm from './DiscountForm'
import LeaseFormDiscountsTable from './LeaseFormDiscountsTable'

export default function DiscountsStep(props) {
  const {
    leaseId,
    currency,
    discounts,
    isLoading,
    fetchDiscountsURL,
    setDiscountTitle,
    setDiscountAmount,
    setDiscountDescription,
    setDiscountStartDate,
    setDiscountEndDate,
    discountTitle,
    discountAmount,
    discountDescription
  } = props
  const { dictionary, t } = useTranslation()
  const [openDiscountForm, setOpenDiscountForm] = useState({
    open: false,
    discountId: null
  })

  const currentYear = new Date().getFullYear()
  const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0')

  const [discountDetails, setDiscountDetails] = useState()
  const [discountMonth, setDiscountMonth] = useState(currentMonth)
  const [discountYear, setDiscountYear] = useState(currentYear)

  const months = [
    { value: '01', label: t('month_names.0') },
    { value: '02', label: t('month_names.1') },
    { value: '03', label: t('month_names.2') },
    { value: '04', label: t('month_names.3') },
    { value: '05', label: t('month_names.4') },
    { value: '06', label: t('month_names.5') },
    { value: '07', label: t('month_names.6') },
    { value: '08', label: t('month_names.7') },
    { value: '09', label: t('month_names.8') },
    { value: '10', label: t('month_names.9') },
    { value: '11', label: t('month_names.10') },
    { value: '12', label: t('month_names.11') }
  ]

  const yearsFormat = Array.from(
    { length: 100 },
    (_, index) => currentYear - 50 + index
  )

  const years = yearsFormat?.map(year => ({
    value: `${year}`,
    label: `${year}`
  }))

  useEffect(() => {
    if (discountMonth && discountYear) {
      setDiscountStartDate(new Date(`${discountYear}-${discountMonth}-01`))
      setDiscountEndDate(new Date(`${discountYear}-${discountMonth}-27`))
    }
  }, [discountMonth, discountYear])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="dark.main">
            {dictionary.discounts}
          </Typography>
          <Typography fontSize={13} mt={1}>
            {dictionary.lease_discounts_desc}
          </Typography>
        </Grid>
        {discounts?.length > 0 && (
          <>
            <Grid item xs={12} align="right">
              <Button
                onClick={() =>
                  setOpenDiscountForm({ open: true, discountId: null })
                }
                data-testid="Button-8cfad762-ce12-48bc-bec6-0c8dbec2ee01"
                sx={{
                  textTransform: 'none',
                  fontSize: '13px',
                  borderRadius: '8px',
                  padding: '5px 30px'
                }}
              >
                {dictionary.add_discount}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <LeaseFormDiscountsTable
                leaseId={leaseId}
                discounts={discounts}
                isLoading={isLoading}
                fetchURL={fetchDiscountsURL}
                currency={currency}
              />
            </Grid>
          </>
        )}
        {!discounts?.length && (
          <Grid item xs={6}>
            <Stack gap={2}>
              <Stack gap={1}>
                <Typography variant="caption">{dictionary.title}*</Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  value={discountTitle}
                  onChange={event => setDiscountTitle(event.target.value)}
                  InputProps={{
                    style: { height: '44px', paddingBottom: '15px' }
                  }}
                />
              </Stack>
              <Stack gap={1}>
                <Typography variant="caption">{t('amount_bt')}*</Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  value={discountAmount}
                  onChange={event => setDiscountAmount(event.target.value)}
                  InputProps={{
                    style: { height: '44px', paddingBottom: '15px' }
                  }}
                />
              </Stack>
              <Stack gap={1}>
                <Typography variant="caption">
                  {dictionary.description}
                </Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  value={discountDescription}
                  onChange={event => setDiscountDescription(event.target.value)}
                  InputProps={{
                    style: { height: '44px', paddingBottom: '15px' }
                  }}
                />
              </Stack>
              <Grid item xs={12} sx={{ display: 'flex', gap: 2 }}>
                <Grid item xs={6}>
                  <Stack gap={1}>
                    <Typography variant="caption">
                      {dictionary.month}*
                    </Typography>
                    <TextField
                      select
                      fullWidth
                      variant="filled"
                      value={discountMonth}
                    >
                      {months.map(month => (
                        <MenuItem
                          key={month.value}
                          value={month.value}
                          onClick={() => setDiscountMonth(month.value)}
                        >
                          {month.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack gap={1}>
                    <Typography variant="caption">
                      {dictionary.year}*
                    </Typography>
                    <TextField
                      select
                      fullWidth
                      variant="filled"
                      value={discountYear}
                      defaultValue={currentYear}
                    >
                      {years.map(year => (
                        <MenuItem
                          key={year.value}
                          value={year.value}
                          onClick={() => setDiscountYear(year.value)}
                        >
                          {year.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        )}
      </Grid>

      <CommonModal
        open={openDiscountForm.open}
        title={`${dictionary.add} ${dictionary.discount}`}
        onClose={() => {
          setOpenDiscountForm({ open: false, discountId: null })
          setDiscountDetails()
        }}
        maxWidth="sm"
      >
        <DiscountForm
          leaseId={leaseId}
          fetchDiscountsURL={fetchDiscountsURL}
          discountDetails={discountDetails}
          handleClose={() => {
            setOpenDiscountForm({ open: false, discountId: null })
            setDiscountDetails()
          }}
        />
      </CommonModal>
    </>
  )
}
