import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from '@contexts/translation'
import API_URL from '@config/services'

import {
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material'

import CommonModal from '@components/common/Modal/CommonModal'
import LotForm from './LotForm'
import LeaseFormLotsTable from './LeaseFormLotsTable'
import { Controller } from 'react-hook-form'
import clsx from 'clsx'
import useButtonTabsStyles from '@styles/buttonTabs/buttonTabs'
import RentExpenseArticleForm from './RentExpenseArticleForm'

export default function RentAndExpensesStep(props) {
  const {
    operationId,
    control,
    errors,
    leaseId,
    articles,
    leaseURL,
    fetchArticlesURL,
    fetchLeasesURL,
    isLoading,
    startDate,
    endDate,
    currency,
    watchMakeDeposit,
    handleDepositMonthsChange,
    handleGuaranteeMonthsChange,
    handleDepositChange,
    handleGuaranteeChange
  } = props
  const { dictionary, t } = useTranslation()
  const classesButtonTabs = useButtonTabsStyles()
  const [filterId, setFilterId] = useState(null)
  const [openArticleForm, setOpenArticleForm] = useState(false)
  const [selectedArticle, setSelectedArticle] = useState(null)

  const handleDropdownChange = event => {
    const selectedId = event.target.value
    setSelectedArticle(selectedId)
    setFilterId(selectedId)
  }

  const fetchURL = API_URL.LEASES.GET_LEASE_ARTICLES(leaseId)

  return (
    <>
      <Grid container gap={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="dark.main">
            {dictionary.rent}
          </Typography>
          <Typography fontSize={13} mt={1}>
            {dictionary.lease_rent_desc}
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Grid
            container
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            pb={1.5}
          >
            <Stack direction="row" spacing={3}>
              <Tabs variant="standard">
                <Tab
                  onClick={() => {
                    setFilterId(null)
                  }}
                  key={'all'}
                  className={clsx(classesButtonTabs.modalRoot, {
                    [classesButtonTabs.modalSelected]: filterId === null
                  })}
                  label={dictionary.all}
                />
                {articles &&
                  articles.slice(0, 10).map((article, idx) => (
                    <Tab
                      key={idx}
                      onClick={() => {
                        setFilterId(article?.id)
                      }}
                      className={clsx(classesButtonTabs.modalRoot, {
                        [classesButtonTabs.modalSelected]:
                          filterId === article?.id
                      })}
                      label={article?.lot_display}
                    />
                  ))}
                {articles?.length > 10 && (
                  <Select
                    value={selectedArticle}
                    onChange={handleDropdownChange}
                    sx={{ height: '44px' }}
                  >
                    <MenuItem value="" disabled>
                      Select an article
                    </MenuItem>
                    {articles.slice(10).map(article => (
                      <MenuItem
                        key={article?.id}
                        value={article?.id}
                        onClick={() => setFilterId(article?.id)}
                        sx={{ cursor: 'pointer' }}
                      >
                        {article?.lot_display}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Tabs>
            </Stack>
            <Grid item>
              <Button
                size="small"
                onClick={() => setOpenArticleForm(true)}
                sx={{ textTransform: 'none', borderRadius: '8px' }}
              >
                {dictionary.manage_lease_articles}
              </Button>
            </Grid>
          </Grid>
          <Divider sx={{ width: '100%' }} />
        </Grid>

        {!filterId && (
          <>
            <Grid item xs={12}>
              <LeaseFormLotsTable
                articles={articles}
                isLoading={isLoading}
                currency={currency}
                setFilterId={setFilterId}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <Stack gap={1}>
                <Typography variant="caption">
                  {t('project_leases_lease_edit_monthly_rent_total')}
                </Typography>
                <Controller
                  control={control}
                  name="total_monthly_rent"
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="filled"
                      disabled
                      error={Boolean(errors.total_monthly_rent)}
                      errorMessage={
                        errors.total_monthly_rent &&
                        errors.total_monthly_rent.message
                      }
                      InputProps={{
                        style: {
                          height: '44px',
                          width: '150px',
                          paddingBottom: '15px'
                        }
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} mt={1} sx={{ display: 'flex', gap: 2 }}>
              <Stack gap={1}>
                <Typography variant="caption">{dictionary['month']}</Typography>
                <Controller
                  control={control}
                  name="deposit_months"
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="filled"
                      type="number"
                      disabled={!watchMakeDeposit}
                      onChange={e => handleDepositMonthsChange(e.target.value)}
                      error={Boolean(errors.deposit_months)}
                      errorMessage={
                        errors.deposit_months && errors.deposit_months.message
                      }
                      InputProps={{
                        style: {
                          height: '44px',
                          width: '150px',
                          paddingBottom: '15px'
                        }
                      }}
                    />
                  )}
                />
              </Stack>
              <Stack gap={1}>
                <Typography variant="caption">{dictionary.deposit}</Typography>
                <Controller
                  control={control}
                  name="deposit"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="filled"
                      disabled={!watchMakeDeposit}
                      onChange={e => handleDepositChange(e.target.value)}
                      InputProps={{
                        style: {
                          height: '44px',
                          width: '150px',
                          paddingBottom: '15px'
                        }
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} mt={1} sx={{ display: 'flex', gap: 2 }}>
              <Stack gap={1}>
                <Typography variant="caption">{dictionary['month']}</Typography>
                <Controller
                  control={control}
                  name="guarantee_months"
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="filled"
                      type="number"
                      disabled={!watchMakeDeposit}
                      onChange={e =>
                        handleGuaranteeMonthsChange(e.target.value)
                      }
                      error={Boolean(errors.guarantee_months)}
                      errorMessage={
                        errors.guarantee_months &&
                        errors.guarantee_months.message
                      }
                      InputProps={{
                        style: {
                          height: '44px',
                          width: '150px',
                          paddingBottom: '15px'
                        }
                      }}
                    />
                  )}
                />
              </Stack>
              <Stack gap={1}>
                <Typography variant="caption">{t('guarantee')}</Typography>
                <Controller
                  control={control}
                  name="guarantee"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="filled"
                      error={Boolean(errors.guarantee)}
                      disabled={!watchMakeDeposit}
                      onChange={e => handleGuaranteeChange(e.target.value)}
                      errorMessage={
                        errors.guarantee && errors.guarantee.message
                      }
                      InputProps={{
                        style: {
                          height: '44px',
                          width: '150px',
                          paddingBottom: '15px'
                        }
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </>
        )}

        {filterId && (
          <RentExpenseArticleForm
            leaseURL={leaseURL}
            fetchArticlesURL={fetchArticlesURL}
            fetchLeasesURL={fetchLeasesURL}
            leaseId={leaseId}
            article={articles?.find(article => article.id === filterId)}
            filterId={filterId}
          />
        )}
      </Grid>

      <CommonModal
        open={openArticleForm}
        title={`${dictionary.add} ${dictionary.lot}`}
        onClose={() => setOpenArticleForm(false)}
        maxWidth="sm"
      >
        <LotForm
          operationId={operationId}
          leaseId={leaseId}
          startDate={startDate}
          endDate={endDate}
          fetchArticlesURL={fetchURL}
          handleClose={() => setOpenArticleForm(false)}
        />
      </CommonModal>
    </>
  )
}

RentAndExpensesStep.propTypes = {
  operationId: PropTypes.string,
  control: PropTypes.object,
  errors: PropTypes.object,
  leaseId: PropTypes.string,
  articles: PropTypes.array,
  leaseURL: PropTypes.string,
  fetchArticlesURL: PropTypes.string,
  fetchLeasesURL: PropTypes.string,
  isLoading: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  currency: PropTypes.string,
  watchMakeDeposit: PropTypes.bool,
  setValue: PropTypes.func
}
