import client from '@config/client'
import API_URL from '@config/services'

export const onCreateLease = payload => {
  return client
    .post(API_URL.LEASES.POST_LEASE(), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onUpdateLease = (leaseId, payload) => {
  return client
    .patch(`/leases/${leaseId}/`, {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onDeleteLease = leaseId => {
  return client
    .delete(`/leases/${leaseId}/`)
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onCreatePeriod = (leaseId, payload) => {
  return client
    .post(API_URL.LEASES.GET_LEASE_PERIODS(leaseId), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onUpdatePeriod = (leaseId, periodId, payload) => {
  return client
    .patch(API_URL.LEASES.PATCH_LEASE_PERIOD(leaseId, periodId), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onDeletePeriod = (leaseId, periodId) => {
  return client
    .delete(API_URL.LEASES.DELETE_LEASE_PERIOD(leaseId, periodId))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onCreateLeaseArticle = (leaseId, payload) => {
  return client
    .post(API_URL.LEASES.POST_LEASE_ARTICLES(leaseId), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onUpdateLeaseArticle = (leaseId, articleId, payload) => {
  return client
    .patch(API_URL.LEASES.DELETE_LEASE_ARTICLE(leaseId, articleId), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onDeleteLeaseArticle = (leaseId, articleId) => {
  return client
    .delete(API_URL.LEASES.DELETE_LEASE_ARTICLE(leaseId, articleId))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onGetLeasesPeriods = () => {
  return client
    .get(API_URL.LEASES.GET_LEASES_PERIODS())
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onGetLeasesActive = id => {
  return client
    .get(`/leases/?operation=${id}&status=active`)
    .then(response => response)
    .catch(error => {
      return error.response
    })
}
