import React from 'react'

import { useTranslation } from '@contexts/translation'

import { CircularProgress, Grid, Tooltip, Typography } from '@mui/material'
import DatesStepDatePicker from './DatesStepDatePicker'

export default function DatesStepPeriods(props) {
  const {
    operationId,
    leaseId = null,
    setLeaseValue,
    periods,
    isLoading,
    control,
    fetchPeriodsURL,
    handleEndDate
  } = props
  const { dictionary } = useTranslation()

  return (
    <Grid
      item
      xs={12}
      sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
    >
      <Grid
        item
        xs={11}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Grid item xs={0.4} />
        <Grid item xs={3}>
          <Typography variant="caption" width="50px">
            {dictionary.start_date}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption" width="150px">
            {dictionary['duration_(in_months)']}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="caption" width="150px">
            {dictionary.end_date}
          </Typography>
        </Grid>
      </Grid>

      {isLoading && <CircularProgress color="secondary" />}

      {!isLoading && !periods?.length && (
        <Tooltip title={!leaseId ? dictionary.select_tenant : null}>
          <DatesStepDatePicker
            disabled={!leaseId}
            operationId={operationId}
            leaseId={leaseId}
            periods={periods}
            order={1}
            control={control}
            fetchPeriodsURL={fetchPeriodsURL}
            handleEndDate={handleEndDate}
            setLeaseValue={setLeaseValue}
          />
        </Tooltip>
      )}

      {!isLoading &&
        periods?.length > 0 &&
        periods
          ?.slice()
          ?.sort((a, b) => a.order - b.order)
          ?.map((period, idx) => (
            <DatesStepDatePicker
              key={idx}
              idx={idx}
              periodId={period?.id}
              periods={periods}
              operationId={operationId}
              leaseId={leaseId}
              startDate={period?.start_date}
              endDate={period?.end_date}
              order={period?.order}
              duration={period?.num_months}
              control={control}
              fetchPeriodsURL={fetchPeriodsURL}
              handleEndDate={handleEndDate}
              setLeaseValue={setLeaseValue}
            />
          ))}
    </Grid>
  )
}
