import React, { useEffect, useState } from 'react'

import { useTranslation } from '@contexts/translation'
import { useSnackbar } from 'notistack'

import { Button, Grid } from '@mui/material'
import TicketsTable from '@components/Ticketing/TicketsTable'
import TicketForm from '@components/Ticketing/TicketForm'
import CommonModal from '@components/common/Modal/CommonModal'
import { useFetchData } from '@api/fetchData'
import DeleteModal from '@components/common/DeleteModal'
import { onDeleteTicket } from '@api/tickets'
import { responseHandler } from '@utils/responseHandler'
import { mutate } from 'swr'
import GeneralFilter from '@components/common/Filter/GeneralFilter'
import queryString from '@utils/queryString'
import { Add } from '@mui/icons-material'

export default function Tickets(props) {
  const { operationId, leaseId, ticketsURL } = props
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [tickets, setTickets] = useState([])
  const [openAddTicket, setOpenAddTicket] = useState({
    open: false,
    id: null
  })
  const [openDeleteTicket, setOpenDeleteTicket] = useState({
    open: false,
    id: null
  })
  const [loading, setLoading] = useState(false)
  const initialQueries = {
    lease: leaseId
  }

  const [queries, setQueries] = useState(initialQueries)
  const SetFilterQuery = (changedFilter, isDeleted = false) => {
    if (isDeleted) {
      setQueries({
        ...initialQueries,
        ...changedFilter
      })
    } else {
      setQueries(query => ({
        ...query,
        ...changedFilter
      }))
    }
  }

  const fetchURL = leaseId ? ticketsURL + '?' + queryString(queries) : null
  const { data, isLoading } = useFetchData(fetchURL)

  useEffect(() => {
    if (data) {
      setTickets(data.results)
    }
  }, [data])

  const onConfirmDelete = async () => {
    setLoading(true)
    const response = await onDeleteTicket(openDeleteTicket.id)

    responseHandler({
      response,
      callback: async () => await mutate(fetchURL),
      dictionary,
      msgSuccess: dictionary.ticket_deleted,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    setOpenDeleteTicket({ open: false, id: null })
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 2
          }}
        >
          <GeneralFilter
            queries={queries}
            setQueries={setQueries}
            SetFilterQuery={SetFilterQuery}
            isPagination
            priority
            status
          />
          <Button
            startIcon={<Add />}
            sx={{ height: '30px' }}
            onClick={() => setOpenAddTicket({ open: true })}
            data-testid="Button-d6d9c4a8-2223-4844-9ffe-ea8d2e77fd0f"
          >
            {dictionary.add_ticket}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TicketsTable
            tickets={tickets}
            isLoading={isLoading}
            handleEdit={ticketId =>
              setOpenAddTicket({ open: true, id: ticketId })
            }
            handleDelete={ticketId =>
              setOpenDeleteTicket({ open: true, id: ticketId })
            }
          />
        </Grid>
      </Grid>

      <CommonModal
        title={
          openAddTicket.id ? dictionary.edit_ticket : dictionary.add_ticket
        }
        open={openAddTicket.open}
        onClose={() => setOpenAddTicket({ open: false, id: null })}
        maxWidth="sm"
      >
        <TicketForm
          operationId={operationId}
          leaseId={leaseId}
          ticketsURL={fetchURL}
          ticketId={openAddTicket.id}
          handleClose={() => setOpenAddTicket({ open: false, id: null })}
        />
      </CommonModal>

      <DeleteModal
        open={openDeleteTicket.open}
        onClose={() => setOpenDeleteTicket({ open: false, id: null })}
        onConfirm={onConfirmDelete}
        description={`${dictionary.confirm_to_delete} ${dictionary.ticket} ?`}
        loading={loading}
      />
    </>
  )
}
