import client from '@config/client'
import API_URL from '@config/services'

export const onCreateTenant = payload => {
  return client
    .post(API_URL.TENANTS.POST_TENANT(), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onDeleteTenant = id => {
  return client
    .delete(API_URL.TENANTS.DELETE_TENANT_BY_ID(id))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onUpdateTenant = (id, payload) => {
  return client
    .patch(API_URL.TENANTS.PATCH_TENANT_BY_ID(id), {
      ...payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onGetTenant = id => {
  return client
    .get(API_URL.TENANTS.GET_TENANT_BY_ID(id))
    .then(response => response.data)
    .catch(error => {
      return error.response
    })
}

export const onGetAllTenants = () => {
  return client
    .get(API_URL.TENANTS.GET_TENANTS_LIST())
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onGetTenantsByOperation = operationId => {
  return client
    .get(API_URL.TENANTS.GET_TENANTS_LIST_BY_PROJECT(operationId))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const addTenantFile = (id, fileId, files) => {
  return client
    .patch(API_URL.TENANTS.PATCH_TENANT_FILE(id), {
      files: files ? [...files, fileId] : [fileId]
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onEnablePortal = (id, type, value) => {
  return client
    .patch(`/${type}s/${id}/`, {
      portal_enabled: value
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onGetTenantInvoices = id => {
  return client
    .get(API_URL.TENANTS.GET_TENANT_INVOICES(id))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}
