import React from 'react'

import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'

import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  Box
} from '@mui/material'
import PlaceHolder from '@components/common/PlaceHolder'
import numberWithCommas from '@utils/numberWithCommas'
import getTotal from '@utils/getTotal'

export default function DiscountsTable(props) {
  const { currency, discounts, isLoading } = props
  const { dictionary, t } = useTranslation()
  const classesTable = useTableStyles()

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer className={classesTable.container}>
            <Table>
              {discounts?.length > 0 && (
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell className={classesTable.headerCell}>
                      {dictionary.title}
                    </TableCell>
                    <TableCell className={classesTable.headerCell}>
                      {dictionary.description}
                    </TableCell>
                    <TableCell className={classesTable.headerCell}>
                      {dictionary.month}
                    </TableCell>
                    <TableCell className={classesTable.headerCell}>
                      {dictionary.year}
                    </TableCell>
                    <TableCell
                      className={classesTable.headerCell}
                      align="right"
                    >
                      {dictionary.amount}
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {isLoading && (
                  <TableRow className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <CircularProgress color="secondary" />
                    </TableCell>
                  </TableRow>
                )}
                {!isLoading && discounts && !discounts?.length && (
                  <TableRow hover={false} className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <Box py={8}>
                        <PlaceHolder
                          src="/Placeholders/Keysy/Baux.png"
                          wordingBordered={t(
                            'projects_leases_lease_no_discounts'
                          )}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}

                {!isLoading &&
                  discounts?.map((discount, idx) => (
                    <TableRow key={idx} className={classesTable.row}>
                      <TableCell>{discount.title}</TableCell>
                      <TableCell>{discount.description}</TableCell>
                      <TableCell>
                        {t(
                          `month_names.${
                            Number(discount.start_date.split('-')[1]) - 1
                          }`
                        )}
                      </TableCell>
                      <TableCell>{discount.end_date.split('-')[2]}</TableCell>
                      <TableCell align="right">
                        {numberWithCommas(discount.amount, 2, currency)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              {!isLoading && discounts?.length > 0 && (
                <TableFooter className={classesTable.footer}>
                  <TableRow hover={false} className={classesTable.rowTotal}>
                    <TableCell colSpan={1} align="left">
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        data-testid="Typography-ffe5e6b1-2857-4002-9005-e1974a1477f3"
                      >
                        {dictionary.total}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={4} align="right">
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        data-testid="Typography-700ea4c5-2c3b-40d7-8fde-32b1298c2730"
                      >
                        {numberWithCommas(
                          getTotal(discounts, 'amount'),
                          2,
                          currency
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}
