import React, { useEffect, useState } from 'react'

import { Controller } from 'react-hook-form'

import { useTranslation } from '@contexts/translation'

import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import makeStyles from '@mui/styles/makeStyles'
import { onSearchCompany } from '@api/contacts'

const useStyles = makeStyles(theme => ({
  suggestionList: {
    borderRadius: '8px',
    border: `1px solid #919eab52`,
    marginTop: 8,
    padding: 5,
    listStyle: 'none',
    '& li:last-child': {
      borderBottom: 'none !important'
    }
  },
  suggestionItem: {
    borderBottom: `1px solid #919eab52`,
    cursor: 'pointer',
    padding: 5,
    '&:hover': { backgroundColor: theme.palette.action.hover }
  }
}))

export default function SirenAutocomplete(props) {
  const { siren, control, errors, setValue } = props
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [suggestions, setSuggestions] = useState(null)
  const [error, setError] = useState()

  useEffect(() => {
    if (errors && errors.siren) {
      setError(errors.siren)
    }
  }, [errors])

  const handleClickSearch = async () => {
    const response = await onSearchCompany({ siren })
    if (response) {
      if (response.status === 200) {
        setSuggestions(response.data.result.resultats_siren)
      }
    }
  }

  const clearSuggestion = () => setSuggestions(null)

  const handleSelect =
    ({
      nom_entreprise,
      siren,
      domaine_activite,
      capital,
      siege,
      forme_juridique
    }) =>
    () => {
      setValue('company_name', nom_entreprise)
      setValue('siren', siren)
      setValue('activity_type', domaine_activite)

      if (forme_juridique) {
        if (forme_juridique === 'Entrepreneur individuel') {
          setValue('entity_type', 'Indi')
        }
        if (forme_juridique === 'SARL, société à responsabilité limitée') {
          setValue('entity_type', 'SARL')
        }
        if (forme_juridique === "SA à conseil d'administration (s.a.i.)") {
          setValue('entity_type', 'SA')
        }
        if (forme_juridique === 'SAS, société par actions simplifiée') {
          setValue('entity_type', 'SAS')
        }
        if (forme_juridique === 'Société en nom collectif') {
          setValue('entity_type', 'SNC')
        }
      }

      if (capital) setValue('capital', capital)
      if (siege)
        setValue(
          'headquarters_address',
          `${siege.adresse_ligne_1} ${siege.code_postal} ${siege.ville}`
        )

      if (siege)
        setValue(
          'address',
          `${siege.adresse_ligne_1} ${siege.code_postal} ${siege.ville}`
        )

      clearSuggestion()
    }

  const renderSuggestions = () =>
    suggestions.map(suggestion => {
      const { nom_entreprise, siren } = suggestion

      return (
        <li
          className={classes.suggestionItem}
          key={siren}
          onClick={handleSelect(suggestion)}
          onKeyDown={event => {
            if (event.key === 'Enter' || event.key === ' ') {
              event.preventDefault()
              event.stopPropagation()
              handleSelect(suggestion)
            }
          }}
        >
          <strong>{nom_entreprise}</strong> <small>{siren}</small>
        </li>
      )
    })

  return (
    <Grid item xs={12}>
      <Typography variant="caption">{dictionary.siren}</Typography>
      <Controller
        control={control}
        name="siren"
        defaultValue=""
        render={({ field }) => (
          <>
            <TextField
              {...field}
              fullWidth
              variant="filled"
              error={Boolean(errors.siren)}
              errorMessage={errors.siren && errors.siren.message}
              InputProps={{
                endAdornment: (siren ?? '').length >= 6 && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickSearch}
                      onMouseDown={e => e.preventDefault()}
                      data-testid="IconButton-35da6bfa-c9b5-4284-8819-6805758e34bd"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  height: '44px',
                  paddingBottom: '15px',
                  overflow: 'hidden'
                }
              }}
            />

            {suggestions && suggestions.length > 0 && (
              <ul className={classes.suggestionList}>{renderSuggestions()}</ul>
            )}

            {error && (
              <div
                style={{
                  color: '#EE0701',
                  marginTop: 4,
                  marginLeft: 8,
                  marginBottom: 0,
                  fontSize: 12
                }}
              >
                {error.message}
              </div>
            )}
          </>
        )}
      />
    </Grid>
  )
}
