import React, { useEffect, useMemo, useState } from 'react'

import { useTranslation } from '@contexts/translation'
import { useSnackbar } from 'notistack'

import { Button, Grid, ListSubheader, MenuItem, TextField } from '@mui/material'
import { format } from 'date-fns'
import { onCreateLeaseArticle, onDeleteLeaseArticle } from '@api/lease'
import { responseHandler } from '@utils/responseHandler'
import { mutate } from 'swr'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import queryString from '@utils/queryString'

export default function LotForm(props) {
  const {
    operationId,
    leaseId,
    startDate,
    endDate,
    fetchArticlesURL,
    handleClose
  } = props
  const { dictionary } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [referenceArticle, setReferenceArticle] = useState([])
  const [selectedLots, setSelectedLots] = useState([])
  const handleChange = event => {
    const {
      target: { value }
    } = event
    setSelectedLots(typeof value === 'string' ? value.split(',') : value)
  }

  const fetchLotsURL =
    API_URL.LOTS.GET_LOTS() +
    '?' +
    queryString({ operation: operationId, is_private: true })
  const { data: lotsData, isLoading: isLoading } = useFetchData(fetchLotsURL)

  const { data: articlesData } = useFetchData(fetchArticlesURL)

  // const lotsIds = useCallback(() => {
  //   let lotsArray = []
  //   lotsData &&
  //     lotsData.results &&
  //     lotsData.results.length > 1 &&
  //     lotsData.results.map(lot => {
  //       lotsArray.push(lot.id)
  //       return lotsArray
  //     })
  //   return lotsArray
  // }, [lotsData])

  const getLots = lots => lots.filter(lot => lot.lot).map(lot => lot.lot)

  useEffect(() => {
    if (articlesData && articlesData.results.length > 0) {
      setReferenceArticle(getLots(articlesData.results))
      setSelectedLots(getLots(articlesData.results))
    }
  }, [articlesData])

  // useEffect(() => {
  //   if (lotsData && lotsData.results && lotsData.results.length > 1) {
  //     const selected = lotsIds()
  //     setSelectedLots(selected)
  //   }
  // }, [lotsData, lotsIds])

  const onSubmitLeaseArticle = async (leaseId, payload) => {
    const response = await onCreateLeaseArticle(leaseId, payload)

    responseHandler({
      response,
      callback: () => {
        return response
      },
      dictionary,
      msgSuccess: dictionary.lease_article_created,
      snackbar: enqueueSnackbar
    })
  }

  const onSubmit = async () => {
    setLoading(true)

    const payload = {
      lease_article: selectedLots
    }
    if (endDate) {
      payload.end_date = format(new Date(endDate), 'dd-MM-yyyy')
    }
    if (startDate) {
      payload.start_date = format(new Date(startDate), 'dd-MM-yyyy')
    }

    const promises = payload.lease_article.map(async lot => {
      payload.lot = lot
      if (referenceArticle.indexOf(lot) === -1) {
        const response = await onSubmitLeaseArticle(leaseId, payload)
        return response
      }
    })

    const createPromise = await Promise.all(promises)
    if (createPromise) {
      const promises = referenceArticle.map(async lot => {
        if (payload.lease_article.indexOf(lot) === -1) {
          let leaseArticleId = articlesData.results.filter(
            article => article.lot === lot
          )

          const response = await onDeleteLeaseArticle(
            leaseId,
            leaseArticleId[0].id
          )

          return response
        }
      })
      const deletePromise = await Promise.all(promises)
      if (deletePromise) {
        // enqueueSnackbar(dictionary.lease_articles_updated, {
        //   variant: 'success'
        // })
        mutate(fetchLotsURL)
        mutate(fetchArticlesURL)
      }
    }

    await mutate(API_URL.LEASES.GET_LEASE(leaseId))
    setLoading(false)
    handleClose?.()
  }

  const othersLots = useMemo(
    () => lotsData?.results?.filter(l => !l.is_parking),
    [lotsData]
  )

  const parkingLots = useMemo(
    () => lotsData?.results?.filter(l => l.is_parking),
    [lotsData]
  )

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              isLoading={isLoading}
              placeholder={dictionary.lease_article}
              label={dictionary.lease_article}
              SelectProps={{
                multiple: true,
                value: selectedLots,
                onChange: handleChange
              }}
            >
              {othersLots?.length > 0 && (
                <ListSubheader>{dictionary.lots}</ListSubheader>
              )}

              {othersLots?.map(lot => (
                <MenuItem
                  key={lot.id}
                  value={lot.id}
                  disabled={!lot.is_vacant}
                  data-testid="MenuItem-ec0228ea-9e4f-4581-8772-b09d03b45adc"
                >
                  {lot.building_display ? `${lot.building_display} : ` : ''}
                  {lot.floor_display ? `${lot.floor_display} : ` : ''}
                  {lot.name}
                  {lot.is_vacant ? `` : ` - ${dictionary.occupied}`}

                  {lot?.type.includes('parking')
                    ? ``
                    : ` - ${lot.rentable_surface.toFixed()}m2`}
                </MenuItem>
              ))}

              {parkingLots?.length > 0 && (
                <ListSubheader>{dictionary.parking}</ListSubheader>
              )}

              {parkingLots?.map(lot => (
                <MenuItem
                  key={lot.id}
                  value={lot.id}
                  disabled={!lot.is_vacant}
                  data-testid="MenuItem-61c16096-4007-43e1-ad42-08ee3a24fe75"
                >
                  {lot.building_display ? `${lot.building_display} : ` : ''}
                  {lot.floor_display ? `${lot.floor_display} : ` : ''}
                  {lot.name}
                  {lot.is_vacant ? ` ` : ` - ${dictionary.occupied}`}
                  {lot?.type.includes('parking')
                    ? ` `
                    : ` - ${lot.rentable_surface.toFixed()}m2`}
                </MenuItem>
              ))}

              {!lotsData && (
                <MenuItem
                  value={0}
                  disabled
                  data-testid="MenuItem-8754521f-da80-4f80-a7c8-7b8f9734133a"
                >
                  {dictionary.loading}
                </MenuItem>
              )}
            </TextField>
          </Grid>
          <Grid item xs={12} align="right">
            <Button
              size="small"
              disabled={loading}
              onClick={onSubmit}
              data-testid="Button-08886cf4-320e-4964-9bda-5a3ea6dbffab"
            >
              {dictionary.confirm}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
