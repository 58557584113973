import React, { useEffect, useState } from 'react'

import { useTranslation } from '@contexts/translation'
import useButtonTabsStyle from '@styles/buttonTabs/buttonTabs'

import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'

import { Badge, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'

import DatesTable from '@components/Leases/LeaseForm/dates/DatesTable'
import clsx from 'clsx'
import DiscountsTable from '@components/Leases/LeaseForm/discounts/DiscountsTable'
import LotsTable from '@components/Leases/LeaseForm/rent-expenses/LotsTable'
import queryString from '@utils/queryString'

export default function LeaseConditionsTab(props) {
  const { leaseId, currency } = props
  const { dictionary } = useTranslation()
  const classesButtonTabs = useButtonTabsStyle()

  const [discounts, setDiscounts] = useState([])

  const [periodsCount, setPeriodsCount] = useState([])
  const [lotsCount, setLotsCount] = useState('0')
  const [discountsCount, setDiscountsCount] = useState('0')
  const fetchPeriodsURL = API_URL.LEASES.GET_LEASE_PERIODS(leaseId)
  const fetchArticlesURL = API_URL.LEASES.GET_LEASE_ARTICLES(leaseId)
  const fetchDiscountsURL =
    API_URL.DISCOUNTS.GET_DISCOUNT_LIST() +
    '?' +
    queryString({ lease: leaseId })
  const { data: periodsData } = useFetchData(fetchPeriodsURL)
  const { data: discountsData, isLoading: isDiscountsLoading } =
    useFetchData(fetchDiscountsURL)
  const { data: articlesData } = useFetchData(fetchArticlesURL)

  useEffect(() => {
    if (periodsData) setPeriodsCount(periodsData.count)
  }, [periodsData])

  useEffect(() => {
    if (articlesData) setLotsCount(articlesData.count)
  }, [articlesData])

  useEffect(() => {
    if (discountsData) {
      setDiscountsCount(discountsData.count)
      setDiscounts(discountsData.results)
    }
  }, [discountsData])

  const [value, setValue] = useState('periods')

  const TAB_ITEMS = [
    {
      id: 0,
      label: dictionary.periods,
      value: 'periods',
      badge: periodsCount,
      component: <DatesTable leaseId={leaseId} />
    },
    {
      id: 1,
      label: dictionary.lots_and_parkings,
      value: 'lots_parkings',
      badge: lotsCount,
      component: <LotsTable leaseId={leaseId} currency={currency} />
    },
    {
      id: 2,
      label: dictionary.discounts,
      value: 'discounts',
      badge: discountsCount,
      component: (
        <DiscountsTable
          leaseId={leaseId}
          currency={currency}
          discounts={discounts}
          setDiscounts={setDiscounts}
          isLaoding={isDiscountsLoading}
          fetchURL={fetchDiscountsURL}
        />
      )
    }
  ]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack>
          <Typography variant="h6">{dictionary.lease_conditions}</Typography>
          <Typography variant="body2" color="text.light">
            {dictionary.lease_details_conditions_desc}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row">
          <Tabs
            variant="standard"
            sx={{
              borderBottom: t => `1px solid ${t.palette.other.stroke}`,
              width: '100%'
            }}
          >
            {TAB_ITEMS.map(tab => (
              <Tab
                key={tab.id}
                className={clsx(classesButtonTabs.modalRoot, {
                  [classesButtonTabs.modalSelected]: value === tab.value
                })}
                onClick={() => setValue(tab.value)}
                label={
                  <Badge
                    badgeContent={tab?.badge?.toString()}
                    color="primary"
                    sx={{
                      fontSize: 12,
                      minWidth: 0,
                      marginRight: 5,
                      '& .MuiBadge-badge': {
                        fontSize: 12,
                        backgroundColor: 'primary.light',
                        color: 'primary.main',
                        fontWeight: 800,
                        borderRadius: '8px',
                        right: '-20px',
                        top: '8px'
                      }
                    }}
                  >
                    {tab.label}
                  </Badge>
                }
              />
            ))}
          </Tabs>
        </Stack>
      </Grid>
      <Grid item xs={12} pl={3} pt={3} pb={3}>
        {TAB_ITEMS.map((tab, i) => (
          <Grid container key={i}>
            {value === tab.value && tab.component}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
