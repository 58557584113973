import React, { useEffect, useState } from 'react'

import { useTranslation } from '@contexts/translation'

import { Box, Grid, Stack, Typography } from '@mui/material'

import { Add } from '@mui/icons-material'
import API_URL from '@config/services'
import queryString from '@utils/queryString'
import { useFetchData } from '@api/fetchData'
import CommonModal from '@components/common/Modal/CommonModal'
import FileForm from '@components/Files/FileForm'

export default function DocumentsStep(props) {
  const { leaseId } = props
  const { dictionary } = useTranslation()
  const [files, setFiles] = useState([])
  const [openFileForm, setOpenFileForm] = useState({
    open: false,
    fileId: null,
    fileName: null,
    file: null
  })
  const filesURL =
    API_URL.FILES.GET_FILES() + '?' + queryString({ leases: leaseId })
  const { data: filesData } = useFetchData(filesURL)

  const [newFileId, setNewFileId] = useState()

  useEffect(() => {
    setFiles(filesData?.results)
  }, [filesData])

  const convertFileName = url => {
    const urlObject = new URL(url)
    const pathname = urlObject.pathname
    return pathname.substring(pathname.lastIndexOf('/') + 1)
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="dark.main">
            {dictionary.documents}
          </Typography>
          <Typography fontSize={13} mt={1}>
            {dictionary.lease_documents_desc}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
            flexWrap: 'wrap',
            height: '250px',
            overflowY: 'auto'
          }}
        >
          {files?.map((file, idx) => (
            <Stack
              key={idx}
              sx={{
                border: '1px solid #E0E3E8',
                width: '30%',
                height: '170px',
                padding: 2,
                borderRadius: theme => theme.spacing(3),
                backgroundColor: 'primary.xtraLight'
              }}
              alignItems="center"
            >
              <Box
                onClick={() =>
                  setOpenFileForm({
                    open: true,
                    fileId: file.id,
                    fileName: file.name,
                    file: file
                  })
                }
                sx={{
                  cursor: 'pointer',
                  height: '300px',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage:
                    file?.file?.includes('.jpeg') ||
                    file?.file?.includes('.png') ||
                    file?.file?.includes('.jpg') ||
                    file?.file?.includes('.gif')
                      ? `url(${file?.file})`
                      : null,
                  '&:hover': {
                    backgroundColor: 'primary.light'
                  }
                }}
              >
                {!file?.file?.includes('.jpeg') &&
                  !file?.file?.includes('.png') &&
                  !file?.file?.includes('.jpg') &&
                  !file?.file?.includes('.gif') && (
                    <div
                      style={{
                        width: 'fit-content',
                        fontSize: '15px',
                        textAlign: 'center'
                      }}
                    >
                      {convertFileName(file?.file)}
                    </div>
                  )}
              </Box>
            </Stack>
          ))}
          <Stack
            sx={{
              border: '1px solid #E0E3E8;',
              width: '30%',
              height: '170px',
              padding: 2,
              borderRadius: theme => theme.spacing(3),
              backgroundColor: 'primary.xtraLight'
            }}
            alignItems="center"
          >
            <Box
              onClick={() =>
                setOpenFileForm({
                  open: true,
                  fileId: null,
                  fileName: null
                })
              }
              sx={{
                cursor: 'pointer',
                height: '300px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2
              }}
            >
              <Add
                sx={{
                  color: '#fff',
                  backgroundColor: 'primary.main',
                  borderRadius: '50%',
                  padding: 0.5,
                  width: '42px',
                  height: '42px'
                }}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <CommonModal
        open={openFileForm.open}
        title={openFileForm.fileId ? dictionary.edit_file : dictionary.add_file}
        onClose={() =>
          setOpenFileForm({
            open: false,
            fileId: null,
            fileName: null
          })
        }
        maxWidth={'sm'}
      >
        <FileForm
          fileId={openFileForm.fileId}
          fileName={openFileForm.fileName}
          filesURL={filesURL}
          selectedFile={openFileForm.file}
          leaseId={leaseId}
          handleClose={() =>
            setOpenFileForm({
              open: false,
              fileId: null,
              fileName: null
            })
          }
          newFileId={newFileId}
          setNewFileId={setNewFileId}
        />
      </CommonModal>
    </>
  )
}
