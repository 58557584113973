import React from 'react'

import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'

import PlaceHolder from '@components/common/PlaceHolder'

import {
  Chip,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import ActionsPopover from '@components/common/ActionsPopover'

export default function TicketsTable(props) {
  const { tickets = [], isLoading, handleEdit, handleDelete } = props
  const { dictionary, t } = useTranslation()
  const classesTable = useTableStyles()

  const priorities = [
    { label: dictionary.urgent, value: '0urgent' },
    { label: dictionary.high, value: '1high' },
    { label: dictionary.medium, value: '2medium' },
    { label: dictionary.low, value: '3low' }
  ]

  const statuses = [
    { label: dictionary.opened, value: 'opened' },
    { label: dictionary.reopened, value: 'reopened' },
    { label: dictionary.resolved, value: 'resolved' },
    { label: dictionary.closed, value: 'closed' },
    { label: dictionary.duplicate, value: 'duplicate' }
  ]

  const getPriority = priority => {
    return priorities.find(p => p.value === priority)?.label
  }

  const getStatus = status => {
    return statuses.find(s => s.value === status)?.label
  }

  const actionsRows = ticket => [
    {
      id: 1,
      label: dictionary.edit,
      icon: <Edit color="secondary" />,
      handleClick: () => {
        handleEdit?.(ticket.id)
      }
    },
    {
      id: 2,
      label: dictionary.delete,
      icon: <Delete color="error" />,
      handleClick: () => {
        handleDelete?.(ticket.id)
      }
    }
  ]

  return (
    <>
      <Grid item xs={12}>
        <TableContainer className={classesTable.container}>
          <Table>
            {tickets && tickets.length > 0 && (
              <TableHead>
                <TableRow hover={false}>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.operation}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.title}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.priority}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.due_date}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="center">
                    {dictionary.status}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading && (
                <TableRow className={classesTable.row}>
                  <TableCell align="center" colSpan={'100%'}>
                    <CircularProgress color="secondary" />
                  </TableCell>
                </TableRow>
              )}

              {!isLoading && tickets && !tickets.length && (
                <TableRow hover={false} className={classesTable.row}>
                  <TableCell align="center" colSpan={'100%'}>
                    <PlaceHolder
                      src="/Placeholders/Keysy/Tickets.png"
                      wordingBordered={t('validation_tickets_no')}
                    />
                  </TableCell>
                </TableRow>
              )}

              {!isLoading &&
                tickets.map((ticket, idx) => (
                  <TableRow key={idx} className={classesTable.row}>
                    <TableCell>{ticket.operation?.name}</TableCell>
                    <TableCell style={{ cursor: 'pointer' }}>
                      <Tooltip title={ticket.content} placement="top">
                        <div>{ticket.title}</div>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{getPriority(ticket.priority)}</TableCell>
                    <TableCell>{ticket.due_date}</TableCell>
                    <TableCell align="center">
                      {ticket.status && (
                        <Chip
                          label={getStatus(ticket.status)}
                          data-testid="Chip-7c3ccd8d-67ff-4059-9193-3670ebacc449"
                        />
                      )}
                    </TableCell>
                    <TableCell className={classesTable.rowActions}>
                      <ActionsPopover actions={actionsRows(ticket)} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  )
}
