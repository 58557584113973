import client from '@config/client'
import API_URL from '@config/services'

export const onCreateTicket = payload => {
  return client
    .post(API_URL.TICKETS.POST_TICKET(), payload)
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onDeleteTicket = ticketId => {
  return client
    .delete(API_URL.TICKETS.DELETE_TICKET(ticketId))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onUpdateTicket = (ticketId, payload) => {
  return client
    .patch(API_URL.TICKETS.PATCH_TICKET(ticketId), payload)
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onGetTicket = ticketId => {
  return client
    .get(API_URL.TICKETS.GET_TICKET(ticketId))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onGetAllTickets = () => {
  return client
    .get(API_URL.TICKETS.GET_TICKETS())
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const postTicketsAction = (orgPk, payload) => {
  return client
    .post(API_URL.TICKETS.POST_TICKETS_ACTION(orgPk), payload)
    .then(response => response)
    .catch(error => {
      return error.response
    })
}
