import makeStyles from '@mui/styles/makeStyles'
export const TableHeight = '550px !important'
export default makeStyles(theme => ({
  container: {
    // border: `1px solid ${theme.palette.other.stroke}`,
    // borderRadius: theme.spacing(1),
    // maxHeight: '800px',
    overflow: 'auto'
  },

  containerFullHeight: {
    border: `1px solid ${theme.palette.other.stroke}`,
    borderRadius: theme.spacing(1),
    overflow: 'auto'
  },

  overflowingContainer: {
    maxWidth: `calc(100vw - ${theme.spacing(36)})`,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      maxWidth: `100vw`
    }
  },

  containerTotal: {
    borderRadius: theme.spacing(1),
    maxHeight: '550px',
    overflow: 'auto'
  },

  headerCell: {
    color: theme.palette.text.disabled
    // fontSize: '12px',
    // textAlign: 'center'
  },
  boldCell: {
    fontWeight: 'bold'
  },
  lightCell: {
    fontWeight: '400'
  },

  bodyCell: {
    textAlign: 'center'
  },

  totalCell: {
    color: theme.palette.secondary.main
  },

  headerRowSticky: {
    height: '48px'
  },

  headerCellSticky: {
    color: theme.palette.text.secondary,
    top: '49px'
  },

  borderCell: {
    borderLeft: `1px solid ${theme.palette.other.stroke} !important`
  },

  narrowCell: {
    padding: '4px 12px'
  },

  smallCell: {
    padding: '4px 2px',
    paddingLeft: '4px'
  },

  row: {
    backgroundColor: `${theme.palette.elements.white}`,
    '&:last-child td, &:last-child th': { borderBottom: 0, borderTop: 0 }
  },

  collapsable: {
    border: 0,
    paddingBottom: 0,
    paddingTop: 0
  },

  rowActions: { textAlign: 'center', width: '0' },

  clickable: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.text.primary
  },

  placeholderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: ' center',
    alignItems: 'center'
  },

  underline: {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.dark.main,
    textUnderlinePosition: 'under'
  },

  // Replace by <Chip />
  totalValue: {
    backgroundColor: theme.palette.highlight.red,
    color: theme.palette.elements.light,
    padding: '3px 8px',
    borderRadius: '.25em'
  },

  rowSelected: {
    backgroundColor: `${theme.palette.primary.xtraLight} !important`,
    '&:last-child td, &:last-child th': { borderBottom: 0 }
  },

  rowSubTotal: {
    background: '#D4F7F040',
    '&:last-child td, &:last-child th': { borderBottom: 0 }
  },

  rowSubTotalOrange: {
    background: '#fff7e8',
    '&:last-child td, &:last-child th': { borderBottom: 0 }
  },

  rowBgStroke: {
    backgroundColor: `${theme.palette.elements.light} !important`
  },

  rowBgGray: {
    backgroundColor: `${theme.palette.elements.main} !important`
  },

  rowTotal: {
    background: theme.palette.secondary.xtraLight,
    color: theme.palette.secondary.main,
    '& td': { color: theme.palette.secondary.main },
    '&:last-child td, &:last-child th': { borderBottom: 0 }
  },

  rowTotalSecondary: {
    background: theme.palette.primary.xtraLight,
    color: theme.palette.primary.main,
    '& td': { color: theme.palette.primary.main },
    '&:last-child td, &:last-child th': { borderBottom: 0 }
  },

  rowTotalDark: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:last-child td, &:last-child th': { borderBottom: 0 }
  },

  footer: {
    '&:last-child td': {
      borderTop: `1px solid ${theme.palette.other.stroke}`,
      borderLeft: 0,
      borderRight: 0,
      borderBottom: 0
      // borderBottomLeftRadius: theme.spacing(1),
      // borderBottomRightRadius: theme.spacing(1)
    }
  },

  footerMTable: {
    '&:last-child td': {
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      borderBottom: 0
      // borderBottomLeftRadius: theme.spacing(1),
      // borderBottomRightRadius: theme.spacing(1)
    }
  }
}))
