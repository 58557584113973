import React, { useState } from 'react'

import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'
import API_URL from '@config/services'

import {
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import numberWithCommas from '@utils/numberWithCommas'
import { ContentCopy, Delete, Edit } from '@mui/icons-material'
import ActionsPopover from '@components/common/ActionsPopover'
import CommonModal from '@components/common/Modal/CommonModal'
import DiscountForm from './DiscountForm'
import DeleteModal from '@components/common/DeleteModal'
import { onDeleteDiscount } from '@api/discounts'
import { responseHandler } from '@utils/responseHandler'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { useTheme } from '@mui/material/styles'

export default function DiscountsTable(props) {
  const { leaseId, discounts, isLoading, fetchURL, currency } = props
  const { dictionary, t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const classesTable = useTableStyles()
  const theme = useTheme()

  const [openDiscountForm, setOpenDiscountForm] = useState({
    open: false,
    id: null
  })
  const [openDeleteDiscount, setOpenDeleteDiscount] = useState({
    open: false,
    id: null
  })

  const handleConfirmDelete = async discountId => {
    const response = await onDeleteDiscount(discountId)

    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchURL)
        mutate(API_URL.LEASES.GET_LEASE_SCHEDULE(leaseId))
      },
      dictionary,
      msgSuccess: dictionary.discount_deleted,
      snackbar: enqueueSnackbar
    })

    setOpenDeleteDiscount({ open: false, id: null })
  }

  const ACTIONS = discountId => [
    {
      id: 1,
      label: dictionary.edit,
      icon: <Edit color="secondary" />,
      handleClick: () => {
        setOpenDiscountForm({ open: true, id: discountId })
      }
    },
    {
      id: 2,
      label: dictionary.delete,
      icon: <Delete color="error" />,
      handleClick: () => {
        setOpenDeleteDiscount({ open: true, id: discountId })
      }
    }
  ]

  const handleDuplicateDiscount = id => {
    setOpenDiscountForm({ open: true, id: null, duplicateId: id })
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer
            sx={{
              maxHeight: '250px',
              overflowY: 'scroll',
              border: `1px solid ${theme.palette.other.stroke}`,
              borderRadius: theme.spacing(3),
              overflow: 'auto'
            }}
          >
            <Table>
              <TableHead>
                <TableRow hover={false} sx={{ height: '67px' }}>
                  {Array(7)
                    .fill()
                    .map((_, idx) => (
                      <TableCell key={idx}></TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow hover={false} sx={{ height: '44px' }}>
                  <TableCell></TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.title}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.description}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.month}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.year}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.amount}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <CircularProgress color="secondary" />
                    </TableCell>
                  </TableRow>
                )}
                {!isLoading &&
                  discounts.map((discount, idx) => (
                    <TableRow
                      key={idx}
                      hover={false}
                      sx={{
                        height: '60px',
                        backgroundColor: `${theme.palette.elements.white}`,
                        '&:last-child td, &:last-child th': {
                          borderBottom: 0,
                          borderTop: 0
                        }
                      }}
                    >
                      <TableCell
                        sx={{
                          '&.MuiTableCell-root': {
                            borderBottom: 'none',
                            paddingLeft: 3
                          }
                        }}
                      >
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: theme.palette.highlight.lightPurple
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}
                      >
                        <Typography variant="caption" fontWeight={700}>
                          {discount.title}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}
                      >
                        <Typography variant="caption">
                          {discount.description}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}
                      >
                        <Typography variant="caption">
                          {t(
                            `month_names.${
                              Number(discount.start_date.split('-')[1]) - 1
                            }`
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}
                      >
                        <Typography variant="caption">
                          {discount.end_date.split('-')[2]}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}
                      >
                        <Typography variant="caption">
                          {numberWithCommas(discount.amount, 2, currency)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '70px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 2,
                          '&.MuiTableCell-root': { borderBottom: 'none' }
                        }}
                      >
                        <Tooltip title={dictionary.duplicate}>
                          <IconButton
                            onClick={() => handleDuplicateDiscount(discount.id)}
                          >
                            <ContentCopy
                              sx={{
                                fontSize: 15,
                                color: 'primary.main',
                                cursor: 'pointer'
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <ActionsPopover actions={ACTIONS(discount.id)} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <CommonModal
        open={openDiscountForm.open}
        title={
          openDiscountForm.duplicateId
            ? `${dictionary.add} ${dictionary.discount}`
            : `${dictionary.edit} ${dictionary.discount}`
        }
        onClose={() => setOpenDiscountForm({ open: false, id: null })}
        maxWidth={'sm'}
      >
        <DiscountForm
          leaseId={leaseId}
          fetchDiscountsURL={fetchURL}
          discountId={openDiscountForm.id}
          duplicateId={openDiscountForm.duplicateId}
          handleClose={() => setOpenDiscountForm({ open: false, id: null })}
        />
      </CommonModal>

      <DeleteModal
        open={openDeleteDiscount.open}
        onClose={() => setOpenDeleteDiscount({ open: false, id: null })}
        onConfirm={() => handleConfirmDelete(openDeleteDiscount.id)}
        description={dictionary.confirm_delete_discount}
      />
    </>
  )
}
