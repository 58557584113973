import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useTranslation } from '@contexts/translation'
import { useSnackbar } from 'notistack'
import { onCreateTenant, onUpdateTenant } from '@api/tenants'
import { responseHandler } from '@utils/responseHandler'
import { mutate } from 'swr'
import PropTypes from 'prop-types'

import { Button, Grid, MenuItem, Stack, Typography } from '@mui/material'
import SirenAutocomplete from '@components/form/SirenAutocomplete'
import CompanyNameAutocomplete from '@components/form/CompanyNameAutocomplete'
import AutocompleteItem from '@components/common/Autocomplete'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import TextFieldItem from '@components/common/Form/TextFieldItem'

export default function TenantForm(props) {
  const {
    operationId,
    tenantsURL,
    tenantURL,
    tenantData,
    setTenantValue,
    handleClose
  } = props
  const { dictionary, t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const { data: operationsList, isLoading: isLoadingOperations } = useFetchData(
    API_URL.PROJECTS.GET_PROJECT_LIST_PK
  )

  const schema = yup.object().shape({
    is_company: yup.boolean(),
    email: yup.string().email(dictionary.invalid_email)
  })

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      is_company: tenantData ? tenantData.is_company : true,
      capital: tenantData ? tenantData.capital : 0
    }
  })

  const watchIsCompany = watch('is_company')
  const watchCompanyName = watch('company_name')
  const watchSiren = watch('siren')

  useEffect(() => {
    if (tenantData) {
      ;(tenantData.is_company
        ? [
            'company_name',
            'activity_type',
            'entity_type',
            'siren',
            'headquarters_address',
            'rdc',
            'representative'
          ]
        : ['first_name', 'last_name']
      ).forEach(item => setValue(item, tenantData[item] || ''))
      tenantData.is_company && setValue('capital', tenantData.capital || 0)
      ;[
        'email',
        'phone',
        'address',
        'website',
        'note',
        'account_number',
        'iban',
        'bic',
        'reference_mandate'
      ].forEach(v => setValue(v, tenantData[v] || ''))
      setValue(
        'operations',
        tenantData.operations.map(o => o.id)
      )
    }
  }, [tenantData, setValue])

  const onCreate = async values => {
    setLoading(true)

    let data

    if (values.is_company) {
      data = {
        account_number: values.account_number,
        activity_type: values.activity_type,
        address: values.address,
        capital: values.capital,
        company_name: values.company_name,
        email: values.email,
        entity_type: values.entity_type,
        headquarters_address: values.headquarters_address,
        is_company: values.is_company,
        note: values.note,
        phone: values.phone,
        rdc: values.rdc,
        representative: values.representative,
        siren: values.siren,
        website: values.website,
        operations: values.operations || [],
        iban: values.iban,
        bic: values.bic,
        reference_mandate: values.reference_mandate
      }
    } else {
      data = {
        account_number: values.account_number,
        address: values.address,
        email: values.email,
        first_name: values.first_name,
        is_company: values.is_company,
        last_name: values.last_name,
        note: values.note,
        phone: values.phone,
        website: values.website,
        operations: values.operations || [],
        iban: values.iban,
        bic: values.bic,
        reference_mandate: values.reference_mandate
      }
    }
    if (operationId) {
      if (tenantData) {
        data['operations'] =
          data.operations && data.operations.length > 0
            ? [...data.operations, Number(operationId)]
            : [Number(operationId)]
      } else {
        data['operations'] = [Number(operationId)]
      }
    }
    if (tenantData) {
      let response = await onUpdateTenant(tenantData.id, data)
      responseHandler({
        response,
        callback: async () => {
          await mutate(tenantURL)
          await mutate(tenantsURL)
          await mutate(API_URL.PROJECTS.GET_PROJECT_ONBOARDING(operationId))
        },
        dictionary,
        msgSuccess: dictionary.tenant_edited,
        snackbar: enqueueSnackbar
      })
    } else {
      let response = await onCreateTenant(data)
      responseHandler({
        response,
        callback: async () => {
          await mutate(tenantsURL)
          await mutate(API_URL.PROJECTS.GET_PROJECT_ONBOARDING(operationId))
          setTenantValue?.(response.data?.id)
        },
        dictionary,
        msgSuccess: dictionary.tenant_created,
        snackbar: enqueueSnackbar
      })
    }

    setLoading(false)
    handleClose?.()
  }

  return (
    <Grid item xs={12}>
      <form
        data-cy={'form'}
        noValidate={true}
        onSubmit={handleSubmit(onCreate)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">{dictionary.type}</Typography>
              <Controller
                control={control}
                name="is_company"
                render={({ field }) => (
                  <TextFieldItem
                    {...field}
                    select
                    fullWidth
                    variant="filled"
                    error={Boolean(errors.is_company)}
                    errorMessage={
                      errors.is_company && errors.is_company.message
                    }
                    InputProps={{
                      style: {
                        height: '44px',
                        paddingBottom: '15px',
                        overflow: 'hidden'
                      }
                    }}
                  >
                    <MenuItem
                      key={'0'}
                      value={true}
                      data-testid="MenuItem-8e7982a7-8cd8-44c7-b778-39dec268216b"
                    >
                      {dictionary.legal_person}
                    </MenuItem>
                    <MenuItem
                      key={'1'}
                      value={false}
                      data-testid="MenuItem-d10af615-071e-4e3b-93b5-70b06bb16af7"
                    >
                      {dictionary.natural_person}
                    </MenuItem>
                  </TextFieldItem>
                )}
              />
            </Stack>
          </Grid>

          {!operationId && (
            <Grid item xs={12}>
              <Stack gap={1}>
                <Typography variant="caption">
                  {dictionary.operations}
                </Typography>
                <AutocompleteItem
                  multiple
                  // showCount
                  name="operations"
                  control={control}
                  label={dictionary.operation}
                  defaultValue={[]}
                  elements={{
                    items: operationsList?.results || [],
                    id: 'id',
                    display: 'display'
                  }}
                  skeletonLoading={isLoadingOperations}
                />
              </Stack>
            </Grid>
          )}

          {watchIsCompany ? (
            <Grid item xs={12}>
              <Stack gap={1}>
                <Typography variant="caption">{dictionary.company}</Typography>
                <CompanyNameAutocomplete
                  companyName={watchCompanyName}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
              </Stack>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Stack gap={1}>
                  <Typography variant="caption">
                    {dictionary.first_name}
                  </Typography>
                  <Controller
                    control={control}
                    name="first_name"
                    defaultValue=""
                    render={({ field }) => (
                      <TextFieldItem
                        {...field}
                        fullWidth
                        variant="filled"
                        label={dictionary.first_name}
                        error={Boolean(errors.first_name)}
                        errorMessage={
                          errors.first_name && errors.first_name.message
                        }
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack gap={1}>
                  <Typography variant="caption">
                    {dictionary.last_name}
                  </Typography>
                  <Controller
                    control={control}
                    name="last_name"
                    defaultValue=""
                    render={({ field }) => (
                      <TextFieldItem
                        {...field}
                        fullWidth
                        variant="filled"
                        label={dictionary.last_name}
                        error={Boolean(errors.last_name)}
                        errorMessage={
                          errors.last_name && errors.last_name.message
                        }
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">{dictionary.email}</Typography>
              <Controller
                control={control}
                name="email"
                defaultValue=""
                render={({ field }) => (
                  <TextFieldItem
                    {...field}
                    type="email"
                    fullWidth
                    variant="filled"
                    error={Boolean(errors.email)}
                    errorMessage={errors.email && errors.email.message}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">{dictionary.phone}</Typography>
              <Controller
                control={control}
                name="phone"
                defaultValue=""
                render={({ field }) => (
                  <TextFieldItem
                    {...field}
                    fullWidth
                    variant="filled"
                    error={Boolean(errors.phone)}
                    errorMessage={errors.phone && errors.phone.message}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">
                {dictionary.billing_address}
              </Typography>
              <Controller
                control={control}
                name="address"
                defaultValue=""
                render={({ field }) => (
                  <TextFieldItem
                    {...field}
                    fullWidth
                    multiline
                    maxRows={2}
                    variant="filled"
                    error={Boolean(errors.address)}
                    errorMessage={errors.address && errors.address.message}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">{dictionary.website}</Typography>
              <Controller
                control={control}
                name="website"
                defaultValue=""
                render={({ field }) => (
                  <TextFieldItem
                    {...field}
                    fullWidth
                    variant="filled"
                    error={Boolean(errors.website)}
                    errorMessage={errors.website && errors.website.message}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">{dictionary.note}</Typography>
              <Controller
                control={control}
                name="note"
                defaultValue=""
                render={({ field }) => (
                  <TextFieldItem
                    {...field}
                    fullWidth
                    variant="filled"
                    error={Boolean(errors.note)}
                    errorMessage={errors.note && errors.note.message}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">
                {dictionary.account_number}
              </Typography>
              <Controller
                control={control}
                name="account_number"
                defaultValue=""
                render={({ field }) => (
                  <TextFieldItem
                    {...field}
                    fullWidth
                    variant="filled"
                    type="number"
                    error={Boolean(errors.account_number)}
                    errorMessage={
                      errors.account_number && errors.account_number.message
                    }
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">{t('iban')}</Typography>
              <Controller
                control={control}
                name="iban"
                defaultValue=""
                render={({ field }) => (
                  <TextFieldItem
                    {...field}
                    fullWidth
                    variant="filled"
                    error={Boolean(errors.iban)}
                    errorMessage={errors.iban && errors.iban.message}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">{t('bic')}</Typography>
              <Controller
                control={control}
                name="bic"
                defaultValue=""
                render={({ field }) => (
                  <TextFieldItem
                    {...field}
                    fullWidth
                    variant="filled"
                    error={Boolean(errors.bic)}
                    errorMessage={errors.bic && errors.bic.message}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography variant="caption">
                {t('reference_mandate')}
              </Typography>
              <Controller
                control={control}
                name="reference_mandate"
                defaultValue=""
                render={({ field }) => (
                  <TextFieldItem
                    {...field}
                    fullWidth
                    variant="filled"
                    error={Boolean(errors.reference_mandate)}
                    errorMessage={
                      errors.reference_mandate &&
                      errors.reference_mandate.message
                    }
                  />
                )}
              />
            </Stack>
          </Grid>

          {watchIsCompany && (
            <>
              <Grid item xs={12}>
                <Stack gap={1}>
                  <Typography variant="caption">
                    {dictionary.activity_type}
                  </Typography>
                  <Controller
                    control={control}
                    name="activity_type"
                    defaultValue=""
                    render={({ field }) => (
                      <TextFieldItem
                        {...field}
                        fullWidth
                        variant="filled"
                        error={Boolean(errors.activity_type)}
                        errorMessage={
                          errors.activity_type && errors.activity_type.message
                        }
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack gap={1}>
                  <Typography variant="caption">
                    {dictionary.entity_type}
                  </Typography>
                  <Controller
                    control={control}
                    name="entity_type"
                    defaultValue=""
                    render={({ field }) => (
                      <TextFieldItem
                        {...field}
                        select
                        fullWidth
                        variant="filled"
                        error={Boolean(errors.entity_type)}
                        errorMessage={
                          errors.entity_type && errors.entity_type.message
                        }
                        InputProps={{
                          style: {
                            height: '44px',
                            paddingBottom: '15px',
                            overflow: 'hidden'
                          }
                        }}
                      >
                        <MenuItem
                          value="particular"
                          data-testid="MenuItem-99125330-e7a3-4398-96b3-183efdb45d80"
                        >
                          {dictionary['Particular']}
                        </MenuItem>
                        <MenuItem
                          value="SA"
                          data-testid="MenuItem-f085afdd-8782-4292-b6b2-1129808924d0"
                        >
                          {dictionary['SA']}
                        </MenuItem>
                        <MenuItem
                          value="SARL"
                          data-testid="MenuItem-d0cd97df-97e6-4963-8bd5-2a6d90b2fdab"
                        >
                          {dictionary['SARL']}
                        </MenuItem>
                        <MenuItem
                          value="SAS"
                          data-testid="MenuItem-e6ac8689-9996-4173-b16e-60565964d836"
                        >
                          {dictionary['SAS']}
                        </MenuItem>
                        <MenuItem
                          value="Indi"
                          data-testid="MenuItem-26c6d65a-8374-49d2-a949-d85e88d45101"
                        >
                          {dictionary['Indi']}
                        </MenuItem>
                        <MenuItem
                          value="SNC"
                          data-testid="MenuItem-35a15d62-797a-48fc-b194-9275763cc02c"
                        >
                          {dictionary['SNC']}
                        </MenuItem>
                      </TextFieldItem>
                    )}
                  />
                </Stack>
              </Grid>
              <SirenAutocomplete
                siren={watchSiren}
                control={control}
                errors={errors}
                setValue={setValue}
              />

              <Grid item xs={12}>
                <Stack gap={1}>
                  <Typography variant="caption">
                    {dictionary.headquarters_address}
                  </Typography>
                  <Controller
                    control={control}
                    name="headquarters_address"
                    defaultValue=""
                    render={({ field }) => (
                      <TextFieldItem
                        {...field}
                        fullWidth
                        variant="filled"
                        multiline
                        maxRows={2}
                        error={Boolean(errors.headquarters_address)}
                        errorMessage={
                          errors.headquarters_address &&
                          errors.headquarters_address.message
                        }
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack gap={1}>
                  <Typography variant="caption">{dictionary.rdc}</Typography>
                  <Controller
                    control={control}
                    name="rdc"
                    defaultValue=""
                    render={({ field }) => (
                      <TextFieldItem
                        {...field}
                        fullWidth
                        variant="filled"
                        error={Boolean(errors.rdc)}
                        errorMessage={errors.rdc && errors.rdc.message}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack gap={1}>
                  <Typography variant="caption">
                    {dictionary.capital}
                  </Typography>
                  <Controller
                    control={control}
                    name="capital"
                    defaultValue={0}
                    render={({ field }) => (
                      <TextFieldItem
                        {...field}
                        fullWidth
                        type="number"
                        variant="filled"
                        error={Boolean(errors.capital)}
                        errorMessage={errors.capital && errors.capital.message}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack gap={1}>
                  <Typography variant="caption">
                    {dictionary.representative}
                  </Typography>
                  <Controller
                    control={control}
                    name="representative"
                    defaultValue=""
                    render={({ field }) => (
                      <TextFieldItem
                        {...field}
                        fullWidth
                        variant="filled"
                        error={Boolean(errors.representative)}
                        errorMessage={
                          errors.representative && errors.representative.message
                        }
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </>
          )}

          <Grid item xs={12} align="right">
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              data-testid="Button-68b1e626-897f-485d-b7c1-ddbf52ec4e06"
            >
              {dictionary.submit}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

TenantForm.propTypes = {
  operationId: PropTypes.number,
  tenantsURL: PropTypes.string,
  tenantURL: PropTypes.string,
  tenantData: PropTypes.object,
  setTenantValue: PropTypes.func,
  handleClose: PropTypes.func
}
