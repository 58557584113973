import { Button, Grid } from '@mui/material'
import { useTranslation } from '@contexts/translation'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useCallback, useEffect, useRef, useState } from 'react'
import CommonModal from '../Modal/CommonModal'
import UploadFile from '../Upload/UploadFile'
import { LoadingButton } from '@mui/lab'
import DeleteModal from '../DeleteModal'

const CropImage = ({
  open,
  onClose,
  file,
  setFile,
  saveImage,
  generalInfoPage = false,
  modalTitle
}) => {
  const { dictionary } = useTranslation()
  const imgRef = useRef(null)
  const initialCrop = { unit: '%', width: '50', height: '100', x: '25' }
  const [crop, setCrop] = useState(initialCrop)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isDelete, setIsDelete] = useState(false)

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  const getCroppedImg = (image, crop, fileName) => {
    if (image && crop) {
      const canvas = document.createElement('canvas')
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      canvas.width = crop.width
      canvas.height = crop.height
      const ctx = canvas.getContext('2d')

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      )

      return new Promise(resolve => {
        canvas.toBlob(blob => {
          if (!blob) {
            return
          }
          blob.name = fileName
          const croppedImageUrl = window.URL.createObjectURL(blob)
          resolve({ croppedImageUrl, blob })
        })
      })
    }
    return undefined
  }
  useEffect(() => {
    const manageImage = async () => {
      if (file && file.file && completedCrop) {
        const { blob } = await getCroppedImg(
          imgRef.current,
          crop,
          file?.file.name
        )

        const _croppedImage = new File([blob], file?.file.name, {
          type: blob.type
        })
        setCroppedImage(_croppedImage)
      }
    }
    manageImage()
  }, [completedCrop])

  return (
    <CommonModal
      open={open}
      title={modalTitle}
      onClose={onClose}
      maxWidth={'md'}
    >
      {file &&
        file.preview &&
        (!generalInfoPage || (generalInfoPage && file.path)) && (
          <Grid item xs={12} align="center" mb={3}>
            <ReactCrop
              src={file.preview}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={c => setCrop(c)}
              onComplete={c => setCompletedCrop(c)}
              minHeight={50}
              minWidth={50}
            />
          </Grid>
        )}

      {file && file.default && (
        <Grid item xs={12} align="center" mb={3}>
          <img src={file.default} />
        </Grid>
      )}

      {file && file.preview && generalInfoPage && !file.path && (
        <Grid item xs={12} align="center" mb={3}>
          <img src={file.preview} style={{ maxWidth: '400px' }} />
        </Grid>
      )}

      {!file && (
        <Grid item xs={12} align="center" mb={3}>
          <UploadFile
            file={file}
            setFile={setFile}
            height={'300px'}
            accept="image/*"
          />
        </Grid>
      )}

      <Grid item xs={12} display="flex" justifyContent="space-between">
        {((!file?.default && !generalInfoPage) ||
          (!file?.preview && generalInfoPage)) && (
          <Button
            variant="outlined"
            onClick={() => {
              onClose()
            }}
            data-testid="Button-fa657d29-b264-4b24-9a4e-6f8cd757d149"
          >
            {dictionary.close}
          </Button>
        )}

        {(file?.default || (file?.preview && generalInfoPage)) && (
          <LoadingButton
            loading={loading}
            variant="outlined"
            color="error"
            onClick={() => setIsDelete(true)}
            data-testid="LoadingButton-26785b45-2ffa-4a35-bb3a-65a9af1325f0"
          >
            {dictionary.delete}
          </LoadingButton>
        )}

        {croppedImage &&
          (!generalInfoPage || (generalInfoPage && file?.path)) && (
            <LoadingButton
              disabled={!file}
              loading={loading}
              onClick={async () => {
                if (!generalInfoPage) {
                  setLoading(true)
                  await saveImage(croppedImage)
                  setLoading(false)
                  onClose()
                  setCrop(initialCrop)
                }
                if (generalInfoPage && croppedImage) {
                  setFile({
                    ...croppedImage,
                    fileData: croppedImage,
                    file: croppedImage,
                    preview: URL.createObjectURL(croppedImage) + '#toolbar=0',
                    blob: file
                  })
                  setCrop(initialCrop)
                  onClose()
                }
              }}
              data-testid="LoadingButton-e4fa55fa-da3d-4cfb-8b0e-b07c479452cc"
            >
              {dictionary.save}
            </LoadingButton>
          )}
      </Grid>

      <DeleteModal
        open={isDelete}
        onClose={() => setIsDelete(false)}
        onConfirm={async () => {
          if (!generalInfoPage) {
            setLoading(true)
            await saveImage('', true)
            setLoading(false)
            setIsDelete(false)
          }
          if (generalInfoPage) {
            setFile(undefined)
            setIsDelete(false)
            setCrop(initialCrop)
          }
        }}
        description={dictionary.confirm_to_delete}
        loading={loading}
      />
    </CommonModal>
  )
}

export default CropImage
