import React, { useEffect, useState } from 'react'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import {
  addDays,
  addMonths,
  differenceInMonths,
  format,
  parse,
  subDays
} from 'date-fns'

import { useTranslation } from '@contexts/translation'
import { onCreatePeriod, onDeletePeriod, onUpdatePeriod } from '@api/lease'
import { responseHandler } from '@utils/responseHandler'

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material'
import { AddCircle, Delete } from '@mui/icons-material'
import DatePicker from '@components/form/DatePicker'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enUS, fr, de, it, es, nl, pt } from 'date-fns/locale'

const DatesStepDatePicker = ({
  name,
  idx,
  operationId,
  leaseId = null,
  periodId,
  startDate,
  endDate,
  duration,
  fullWidth,
  required,
  disabled,
  clearable,
  width,
  margin = 'normal',
  size = 'medium',
  textFieldSize = 'small',
  inputFormat = 'dd/MM/yyyy',
  defaultValue = null,
  shouldDisableDate = () => null,
  data_testid,
  InputProps = {},
  order,
  periods,
  fetchPeriodsURL,
  setLeaseValue,
  ...props
}) => {
  const { dictionary, lang } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const formatDates = dateString => {
    if (!dateString) return
    const split = dateString.split('-')
    const day = split[0]
    const month = split[1]
    const year = split[2]
    return year + '-' + month + '-' + day
  }

  const schema = yup.object().shape({
    start_date: yup.date().typeError(dictionary.required),
    end_date: yup
      .date()
      .typeError(dictionary.required)
      .min(yup.ref('start_date'), dictionary.end_date_after_start),
    duration: yup.string()
  })

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  const watchStartDate = watch('start_date')
  let tempDuration
  let tempEndDate

  useEffect(() => {
    if (periodId) {
      if (startDate && endDate) {
        setValue('start_date', parse(startDate, 'dd-MM-yyyy', new Date()))
        setValue('end_date', parse(endDate, 'dd-MM-yyyy', new Date()))
        setValue('order', order)
        setValue('duration', duration)
      }
    }
  }, [setValue])

  const onSubmit = async values => {
    setLoading(true)

    const data = {
      operation: operationId,
      start_date: format(new Date(values.start_date), 'dd-MM-yyyy'),
      end_date: format(new Date(values.end_date), 'dd-MM-yyyy')
    }

    let response = periodId
      ? await onUpdatePeriod(leaseId, periodId, data)
      : await onCreatePeriod(leaseId, data)

    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchPeriodsURL)
        setValue('start_date', values.start_date)
        setValue('end_date', values.end_date)

        if (response.data?.order == 1) {
          setLeaseValue('start_date', values.start_date)
        }
        setLeaseValue('end_date', values.end_date)
      },
      dictionary,
      msgSuccess: dictionary.period_updated,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
  }

  const handleAddClick = async () => {
    setLoading(true)

    const data = {
      operation: operationId,
      start_date: format(
        addDays(new Date(formatDates(periods?.[idx]?.end_date)), 1),
        'dd-MM-yyyy'
      ),
      end_date: format(
        addMonths(new Date(formatDates(periods?.[idx]?.end_date)), 12),
        'dd-MM-yyyy'
      )
    }

    let response = await onCreatePeriod(leaseId, data)
    responseHandler({
      response,
      callback: async () => await mutate(fetchPeriodsURL),
      dictionary,
      msgSuccess: dictionary.period_created,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
  }

  const handleDeleteClick = async id => {
    setLoading(true)

    const response = await onDeletePeriod(leaseId, id)
    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchPeriodsURL)
        idx !== 0
          ? setLeaseValue(
              'end_date',
              new Date(formatDates(periods[idx - 1]?.end_date))
            )
          : setLeaseValue('end_date', null)
        idx === 0 && setLeaseValue('start_date', null)
      },
      dictionary,
      msgSuccess: dictionary.period_deleted,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
  }

  const handleDurationChange = value => {
    if (watchStartDate && value) {
      setValue('duration', value)
      tempEndDate = subDays(addMonths(watchStartDate, value), 1)
      if (value > 0) setValue('end_date', tempEndDate)
    }
  }

  const handleEndDateChange = value => {
    if (watchStartDate && value) {
      tempDuration = differenceInMonths(addDays(value, 1), watchStartDate)
      setValue('duration', tempDuration)
    }
  }

  const localeMap = {
    en: { locale: enUS, placeholder: 'dd/mm/yyyy' },
    fr: { locale: fr, placeholder: 'jj/mm/aaaa' },
    de: { locale: de, placeholder: 'tt/mm/jjjj' },
    it: { locale: it, placeholder: 'gg/mm/aaaa' },
    es: { locale: es, placeholder: 'dd/mm/aaaa' },
    nl: { locale: nl, placeholder: 'dd/mm/jjjj' },
    pt: { locale: pt, placeholder: 'jj/mm/aaaa' }
  }

  return (
    <form data-cy={'form'} noValidate={true} onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid
          item
          xs={11}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Grid item xs={0.4} sx={{ display: 'flex', justifyContent: 'start' }}>
            {periodId && idx === periods?.length - 1 && (
              <Tooltip title={dictionary.add_period}>
                <Button
                  sx={{
                    width: '32px',
                    backgroundColor: 'white',
                    minWidth: 0,
                    '&:disabled': { backgroundColor: 'white' },
                    '&:hover': { backgroundColor: 'white' }
                  }}
                  onClick={handleAddClick}
                  disabled={disabled}
                >
                  <AddCircle
                    sx={{
                      fontSize: '20px',
                      color: 'primary.main',
                      cursor: 'pointer',
                      '&:hover': { color: 'primary.dark' }
                    }}
                  />
                </Button>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              name="start_date"
              defaultValue=""
              width="90%"
              control={control}
              disabled={disabled}
              InputProps={{
                style: { height: '44px' }
              }}
              sx={{
                '& .MuiFilledInput-input': {
                  paddingTop: 1.5,
                  paddingBottom: 1.5
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              control={control}
              name="duration"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  type="number"
                  disabled={disabled}
                  error={Boolean(errors.duration)}
                  helperText={errors.duration && errors.duration.message}
                  onChange={e => handleDurationChange(e.target.value)}
                  inputProps={{ min: 0 }}
                  InputProps={{
                    style: {
                      height: '44px',
                      width: '85%',
                      paddingBottom: '15px'
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl margin="normal" size="medium" style={{ width: '90%' }}>
              <Controller
                name="end_date"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[lang]?.locale}
                  >
                    <DesktopDatePicker
                      views={['year', 'month', 'day']}
                      value={endDate}
                      inputFormat="dd/MM/yyyy"
                      disabled={disabled}
                      onChange={handleEndDateChange(field?.value)}
                      InputProps={{
                        style: { height: '44px' }
                      }}
                      {...field}
                      renderInput={params => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: localeMap[lang]?.placeholder
                          }}
                          disabled={disabled}
                          name="end_date"
                          style={{
                            backgroundColor: '#fff'
                          }}
                          sx={{
                            '& .MuiFilledInput-input': {
                              paddingTop: 1.5,
                              paddingBottom: 1.5
                            }
                          }}
                        />
                      )}
                      minDate={new Date(1970, 0, 1)}
                      maxDate={new Date(2100, 11, 31)}
                    />
                  </LocalizationProvider>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1.7}>
            <Button
              type="submit"
              disabled={disabled || loading}
              sx={{
                height: '44px',
                borderRadius: '8px',
                fontSize: '12px',
                textTransform: 'none'
              }}
            >
              {periodId ? dictionary.update : dictionary.validate}
            </Button>
          </Grid>
          <Grid item xs={0.5} sx={{ textAlign: 'left' }}>
            {periodId && (
              <IconButton
                onClick={() => handleDeleteClick(periodId)}
                aria-label="delete"
                sx={{
                  color: 'primary.main',
                  padding: 0,
                  '&:hover': { backgroundColor: 'white' }
                }}
              >
                <Delete
                  sx={{
                    '&:hover': { color: 'primary.dark' }
                  }}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default DatesStepDatePicker
