import React, { useReducer, useState } from 'react'

import { useTranslation } from '@contexts/translation'

import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'

import { Grid, Stack, Typography } from '@mui/material'
import InvoicingScheduleTable from '@components/Leases/LeaseForm/discounts/InvoicingScheduleTable'
import PaymentsTab from './PaymentsTab'
import InvoicesTab from './InvoicesTab'
import { queriesReducer } from '@utils/commonReducerFunctions'
import formatURL from '@utils/formatURL'
import ButtonTabs from '@components/common/ButtonTabs'

export default function InvoicingTab(props) {
  const { operationId, leaseId, currency, leaseData } = props
  const { dictionary } = useTranslation()

  const [value, setValue] = useState('invoicing_schedule')

  const [filterQueries, dispatchFilterQueries] = useReducer(queriesReducer, {})
  const [invoicesQueries, dispatchInvoicesQueries] = useReducer(
    queriesReducer,
    { page: 1, page_size: 50, q: '' }
  )
  const invoicesURL = formatURL(API_URL.INVOICES.GET_INVOICES_LIST_PK(), {
    operation: operationId,
    lease: leaseId,
    ...invoicesQueries,
    ...filterQueries
  })
  const { data: invoicesList, isLoading: isLoadingInvoices } =
    useFetchData(invoicesURL)

  const fetchPaymentsURL = formatURL(API_URL.PAYMENTS.GET_PEYMENTS_LIST_PK(), {
    operation: operationId,
    tenant: leaseData?.tenant?.id,
    page_size: 9999
  })
  const { data: paymentsData } = useFetchData(fetchPaymentsURL)

  const TAB_ITEMS = [
    {
      text: dictionary.invoicing_schedule,
      onClick: () => setValue('invoicing_schedule'),
      selected: value === 'invoicing_schedule',
      component: (
        <InvoicingScheduleTable leaseId={leaseId} currency={currency} />
      )
    },
    {
      text: dictionary.invoices,
      onClick: () => setValue('invoices'),
      selected: value === 'invoices',
      badgeCount: invoicesList?.count,
      component: (
        <InvoicesTab
          operationId={operationId}
          leaseId={leaseId}
          dataProps={{
            invoicesList,
            isLoadingInvoices,
            invoicesURL
          }}
          queriesProps={{
            invoicesQueries,
            dispatchInvoicesQueries,
            filterQueries,
            dispatchFilterQueries
          }}
        />
      )
    },
    {
      text: dictionary.payments,
      onClick: () => setValue('payments'),
      selected: value === 'payments',
      badgeCount: paymentsData?.count,
      component: (
        <PaymentsTab leaseId={leaseId} tenantId={leaseData?.tenant?.id} />
      )
    }
  ]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack>
          <Typography variant="h6">{dictionary.lease_conditions}</Typography>
          <Typography variant="body2" color="text.light">
            {dictionary.lease_details_conditions_desc}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <ButtonTabs tabs={TAB_ITEMS} />
      </Grid>
      <Grid item xs={12}>
        {TAB_ITEMS.map((tab, i) => (
          <Grid container key={i}>
            {tab.selected && tab.component}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
