import React, { useEffect, useState } from 'react'

import { useTranslation } from '@contexts/translation'
import { useSnackbar } from 'notistack'

import { Button, Grid, Stack, TextField, Typography } from '@mui/material'

import { Controller, useForm } from 'react-hook-form'
import { onUpdateLeaseArticle } from '@api/lease'
import { responseHandler } from '@utils/responseHandler'
import { mutate } from 'swr'
import PropTypes from 'prop-types'

export default function RentExpenseArticleForm(props) {
  const {
    leaseId,
    filterId,
    article,
    leaseURL,
    fetchArticlesURL,
    fetchLeasesURL
  } = props
  const { dictionary, t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [inputWarnings, setInputWarnings] = useState({})

  const validateNumberInput = (value, fieldName) => {
    const trimmedValue = value.trim()
    const regex = /^[0-9]*\.?[0-9]*$/
    if (!regex.test(trimmedValue)) {
      setInputWarnings(prev => ({
        ...prev,
        [fieldName]: t('projects_leases_lease_number_input_error')
      }))
    } else {
      setInputWarnings(prev => ({
        ...prev,
        [fieldName]: ''
      }))
    }
  }

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      is_indexed: true
    }
  })

  const contract_surface_area = watch('contract_surface_area')
  const price_per_surface = watch('price_per_surface')
  const contract_budget_per_surface = watch('contract_budget_per_surface')

  useEffect(() => {
    setValue('monthly_rent', article?.monthly_rent)
    setValue('monthly_contract_budget', article?.monthly_budget_provision)
    setValue('monthly_budget_provision', article?.monthly_budget_provision)
    setValue(
      'monthly_rent_calculated',
      article?.monthly_rent + article?.monthly_budget_provision
    )
    setValue('rent', article?.rent)
    setValue('yearly_contract_budget', article?.budget_provision)
    setValue('budget_provision', article?.budget_provision)
    setValue('price_per_surface', article?.price_per_surface)
    setValue(
      'contract_budget_per_surface',
      article?.contract_budget_per_surface
    )
    setValue('contract_surface_area', article?.contract_surface_area)
  }, [filterId, setValue])

  const handleMonthlyRentChange = value => {
    validateNumberInput(value, 'monthly_rent')
    setValue('monthly_rent', value)
    setValue('rent', value * 12)
    setValue('price_per_surface', (value * 12) / contract_surface_area)
  }

  const handleRentChange = value => {
    validateNumberInput(value, 'rent')
    setValue('rent', value)
    setValue('monthly_rent', value / 12)
    setValue('price_per_surface', value / contract_surface_area)
  }

  const handleYearlyContractChange = value => {
    validateNumberInput(value, 'yearly_contract_budget')
    setValue('yearly_contract_budget', value)
    setValue('monthly_contract_budget', value / 12)
    setValue('contract_budget_per_surface', value / contract_surface_area)
  }

  const handleMonthlyContractChange = value => {
    validateNumberInput(value, 'monthly_contract_budget')
    setValue('monthly_contract_budget', value)
    setValue('yearly_contract_budget', value * 12)
    setValue(
      'contract_budget_per_surface',
      (value * 12) / contract_surface_area
    )
  }

  const handlePricePerSurfaceChange = value => {
    validateNumberInput(value, 'price_per_surface')
    setValue('price_per_surface', value)
    setValue('rent', value * contract_surface_area)
    setValue('monthly_rent', (value * contract_surface_area) / 12)
  }

  const handleContractBudgetPerSurfaceChange = value => {
    validateNumberInput(value, 'contract_budget_per_surface')
    setValue('contract_budget_per_surface', value)
    setValue('yearly_contract_budget', value * contract_surface_area)
    setValue('monthly_contract_budget', (value * contract_surface_area) / 12)
  }

  const handleSurfaceChange = value => {
    validateNumberInput(value, 'contract_surface_area')
    setValue('contract_surface_area', value)
    setValue('rent', price_per_surface * value)
    setValue('monthly_rent', (price_per_surface * value) / 12)
    setValue('yearly_contract_budget', contract_budget_per_surface * value)
    setValue(
      'monthly_contract_budget',
      (contract_budget_per_surface * value) / 12
    )
  }

  const onSubmit = async () => {
    const payload = getValues()
    const newPayload = {
      price_per_surface: parseFloat(payload.price_per_surface),
      contract_budget_per_surface: parseFloat(
        payload.contract_budget_per_surface
      ),
      contract_surface_area: parseFloat(payload.contract_surface_area)
    }

    let response = await onUpdateLeaseArticle(leaseId, filterId, newPayload)

    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchArticlesURL)
        await mutate(fetchLeasesURL)
        await mutate(leaseURL)
      },
      dictionary,
      msgSuccess: t('projects_leases_article_updated'),
      snackbar: enqueueSnackbar
    })
  }

  return (
    <Grid item xs={12}>
      <form
        data-cy={'form'}
        noValidate={true}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={3}>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Stack gap={1}>
              <Typography variant="caption">
                {t('projects_leases_lease_rent_excluding_charges_(month)')}
              </Typography>
              <Controller
                control={control}
                name="monthly_rent"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="filled"
                    onChange={e => handleMonthlyRentChange(e.target.value)}
                    error={Boolean(
                      errors.monthly_rent || inputWarnings.monthly_rent
                    )}
                    helperText={inputWarnings.monthly_rent}
                    errorMessage={
                      errors.monthly_rent && errors.monthly_rent.message
                    }
                    InputProps={{
                      style: {
                        height: '44px',
                        width: '88%',
                        paddingBottom: '15px'
                      }
                    }}
                  />
                )}
              />
            </Stack>
            <Stack gap={1}>
              <Typography variant="caption">
                {t('projects_leases_lease_charges_(month)')}
              </Typography>
              <Controller
                control={control}
                name="monthly_contract_budget"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="filled"
                    onChange={e => handleMonthlyContractChange(e.target.value)}
                    error={Boolean(
                      errors.monthly_contract_budget ||
                        inputWarnings.monthly_contract_budget
                    )}
                    helperText={inputWarnings.monthly_contract_budget}
                    errorMessage={
                      errors.monthly_contract_budget &&
                      errors.monthly_contract_budget.message
                    }
                    InputProps={{
                      style: {
                        height: '44px',
                        width: '88%',
                        paddingBottom: '15px'
                      }
                    }}
                  />
                )}
              />
            </Stack>
            <Stack gap={1}>
              <Typography variant="caption">
                {t('project_leases_lease_edit_contract_surface_area')}
              </Typography>
              <Controller
                control={control}
                name="contract_surface_area"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="filled"
                    onChange={e => handleSurfaceChange(e.target.value)}
                    error={Boolean(
                      errors.contract_surface_area ||
                        inputWarnings.contract_surface_area
                    )}
                    helperText={inputWarnings.contract_surface_area}
                    errorMessage={
                      errors.contract_surface_area &&
                      errors.contract_surface_area.message
                    }
                    InputProps={{
                      style: {
                        height: '44px',
                        width: '88%',
                        paddingBottom: '15px'
                      }
                    }}
                  />
                )}
              />
              <Typography
                variant="caption"
                color={theme => theme.palette.text.disabled}
              >
                ({t('surface')}: {article?.surface_area})
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Stack gap={1}>
              <Typography variant="caption">
                {t('projects_leases_lease_rent_excluding_charges_(year)')}
              </Typography>
              <Controller
                control={control}
                name="rent"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="filled"
                    onChange={e => handleRentChange(e.target.value)}
                    error={Boolean(errors.rent || inputWarnings.rent)}
                    helperText={inputWarnings.rent}
                    errorMessage={errors.rent && errors.rent.message}
                    InputProps={{
                      style: {
                        height: '44px',
                        width: '88%',
                        paddingBottom: '15px'
                      }
                    }}
                  />
                )}
              />
            </Stack>
            <Stack gap={1}>
              <Typography variant="caption">
                {t('projects_leases_lease_charges_(year)')}
              </Typography>
              <Controller
                control={control}
                name="yearly_contract_budget"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="filled"
                    onChange={e => handleYearlyContractChange(e.target.value)}
                    error={Boolean(
                      errors.yearly_contract_budget ||
                        inputWarnings.yearly_contract_budget
                    )}
                    helperText={inputWarnings.yearly_contract_budget}
                    errorMessage={
                      errors.yearly_contract_budget &&
                      errors.yearly_contract_budget.message
                    }
                    InputProps={{
                      style: {
                        height: '44px',
                        width: '88%',
                        paddingBottom: '15px'
                      }
                    }}
                  />
                )}
              />
            </Stack>
            <Stack height="67.5px" />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Stack gap={1}>
              <Typography variant="caption">
                {t('projects_leases_lease_rent_excluding_charges_(m2)')}
              </Typography>
              <Controller
                control={control}
                name="price_per_surface"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="filled"
                    onChange={e => handlePricePerSurfaceChange(e.target.value)}
                    error={Boolean(
                      errors.price_per_surface ||
                        inputWarnings.price_per_surface
                    )}
                    helperText={inputWarnings.price_per_surface}
                    errorMessage={
                      errors.price_per_surface &&
                      errors.price_per_surface.message
                    }
                    InputProps={{
                      style: {
                        height: '44px',
                        width: '88%',
                        paddingBottom: '15px'
                      }
                    }}
                  />
                )}
              />
            </Stack>
            <Stack gap={1}>
              <Typography variant="caption">
                {t('projects_leases_lease_charges_(m2)')}
              </Typography>
              <Controller
                control={control}
                name="contract_budget_per_surface"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="filled"
                    onChange={e =>
                      handleContractBudgetPerSurfaceChange(e.target.value)
                    }
                    error={Boolean(
                      errors.contract_budget_per_surface ||
                        inputWarnings.contract_budget_per_surface
                    )}
                    helperText={inputWarnings.contract_budget_per_surface}
                    errorMessage={
                      errors.contract_budget_per_surface &&
                      errors.contract_budget_per_surface.message
                    }
                    InputProps={{
                      style: {
                        height: '44px',
                        width: '88%',
                        paddingBottom: '15px'
                      }
                    }}
                  />
                )}
              />
            </Stack>
            {/* <Stack gap={1}>
              <Typography variant="caption">
                {dictionary['tax_provisions_(m2)']}
              </Typography>
              <Controller
                control={control}
                name="budget_provision_per_surface"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    disabled
                    variant="filled"
                    error={Boolean(errors.budget_provision_per_surface)}
                    errorMessage={
                      errors.budget_provision_per_surface &&
                      errors.budget_provision_per_surface.message
                    }
                    InputProps={{
                      style: {
                        height: '44px',
                        width: '88%',
                        paddingBottom: '15px'
                      }
                    }}
                  />
                )}
              />
            </Stack> */}
            <Stack height="62px" />
            <Stack
              height="67.5px"
              sx={{ display: 'flex', justifyContent: 'end' }}
            >
              <Button
                type="submit"
                sx={{
                  height: '44px',
                  borderRadius: '8px',
                  fontSize: '12px',
                  textTransform: 'none',
                  width: '88%'
                }}
              >
                {dictionary.save}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

RentExpenseArticleForm.propTypes = {
  leaseId: PropTypes.number,
  filterId: PropTypes.number,
  article: PropTypes.object,
  leaseURL: PropTypes.string,
  fetchArticlesURL: PropTypes.string,
  fetchLeasesURL: PropTypes.string
}
